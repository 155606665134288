import {
    autoUpdate,
    offset,
    shift,
    useFloating,
    arrow,
} from "@floating-ui/react";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useRef, ReactNode } from "react";

interface TooltipProps {
    children: ReactNode;
}

export const HelperTooltip = (props: TooltipProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const arrowRef = useRef(null);

    const { refs, floatingStyles } = useFloating({
        strategy: "fixed",
        placement: "right",
        middleware: [
            offset({
                mainAxis: 5,
            }),
            shift(),
            arrow({ element: arrowRef }),
        ],
        whileElementsMounted: autoUpdate,
    });

    return (
        <div className="relative inline-block">
            {/* Icon that triggers the tooltip */}
            <FontAwesomeIcon
                icon={faQuestionCircle}
                className="w-4 cursor-pointer"
                ref={refs.setReference}
                onMouseEnter={() => setIsOpen(true)}
                onMouseLeave={() => setIsOpen(false)}
            />

            {/* Tooltip content */}
            {isOpen && (
                <div
                    ref={refs.setFloating}
                    style={floatingStyles}
                    className="bg-ae-slate-100 border rounded p-2 shadow-lg z-10"
                >
                    {props.children}
                </div>
            )}
        </div>
    );
};
