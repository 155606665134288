import { ReactNode } from "react";

interface ButtonProps {
    children: string | ReactNode;
    variant?: "sm" | "lg";
    disabled?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const PrimaryButton = (props: ButtonProps) => (
    <button
        onClick={props.onClick}
        className={`
            rounded justify-center items-center
            ${props.variant === "sm" ? "h-7 px-2 py-1" : "h-8 px-4 py-1.5 "}
            ${
                props.disabled
                    ? "bg-gray-300"
                    : "bg-ae-blue-900 hover:bg-ae-blue-500"
            }
        `}
        disabled={props.disabled}
    >
        <div className="text-white text-sm font-medium flex items-center gap-2 whitespace-nowrap">
            {props.children}
        </div>
    </button>
);

export const SecondaryButton = (props: ButtonProps) => (
    <button
        type="button"
        onClick={!props.disabled && props.onClick}
        className={`
            px-4 text-sm text-neutral-600
            bg-white border border-ae-gray-250 rounded justify-center items-center
            ${props.variant === "sm" ? "h-7 py-1" : "h-8 py-1.5 "}
            ${
                props.disabled
                    ? "bg-gray-300 cursor-default"
                    : "hover:border-ae-blue-400"
            }
        `}
    >
        <div className="text-ae-blue-900 text-sm font-medium flex items-center gap-2">
            {props.children}
        </div>
    </button>
);

export const PillButton = (props: ButtonProps) => (
    <button
        onClick={props.onClick}
        className="px-1 py-0.5 bg-white border border-ae-blue-900 rounded justify-center items-center hover:bg-ae-gray-200"
    >
        <div className="text-ae-blue-900 text-sm font-medium flex items-center gap-2">
            {props.children}
        </div>
    </button>
);

interface InputButtonProps {
    onClick?: () => void;
    children: ReactNode;
    active?: boolean;
    disabled?: boolean;
    className?: string;
}

export const InputButton = (props: InputButtonProps) => (
    <button
        type="button"
        onClick={() => !props.disabled && props.onClick && props.onClick()}
        disabled={props.disabled}
        className={`
            flex text-sm items-center justify-center border
            ${
                props.disabled
                    ? "text-gray bg-gray-200"
                    : props.active
                      ? "bg-ae-blue-550 text-white border-ae-blue-550 cursor-default"
                      : "bg-white hover:bg-ae-gray-100 border-ae-gray-250"
            }
            ${props.className}
        `}
    >
        {props.children}
    </button>
);
