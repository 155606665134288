import { ReactNode } from "react";

export const FormSection = ({
    children,
    title,
    description,
}: {
    children: ReactNode;
    title?: string;
    description?: string;
}) => (
    <div className="w-full p-4 bg-white rounded text-sm mb-4">
        {title && (
            <p className="font-semibold text-base mb-1 text-black">{title}</p>
        )}
        {description && <p className="text-neutral-600">{description}</p>}
        {(title || description) && <div className="mb-6" />}
        {children}
    </div>
);

export const SubmitToCdxNotice = () => (
    <div className="w-full rounded bg-ae-blue-900 text-white px-4 py-2 mb-4">
        Please note that the form included here must be submitted to the EPA.
        You can complete the submission by visiting
        <span className="ml-1 font-bold underline">
            EPA's Central Data Exchange (CDX)
        </span>
        .
    </div>
);

export const EpaMatchedInfo = () => (
    <div className="w-full rounded bg-ae-blue-900 text-white px-4 py-2 mb-4">
        This event was identified as a possible match to your infrastructure by
        AERSHED based on proximity of latitude/longitude scraped from the EPA
        SEP page.
    </div>
);

export const CertifyNotice = () => (
    <div className="w-full rounded bg-ae-blue-900 text-white px-4 py-2 mt-4">
        <span className="mr-1 font-bold">
            Please note that you must certify
        </span>
        "that all applicable investigations specified in paragraph (d)(6)(i)
        through (v) of this section have been conducted for all affected
        facilities and associated equipment subjects to this subpart that are at
        this oil and natural gas facility”
        <span className="ml-1 font-bold">
            when you submit this form to the EPA.
        </span>
        <p className="mt-2">
            You can complete the submission by visiting
            <span className="ml-1 font-bold underline">
                EPA's Central Data Exchange (CDX)
            </span>
            .
        </p>
    </div>
);
