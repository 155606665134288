import type { FeatureCollection } from "geojson";
import { useMap } from "./hooks/mapState";
import { useMemo, useState } from "react";
import * as turf from "@turf/turf";
import { GeoJsonLayer } from "deck.gl";
import { MapBase } from "./Map";
import {
    EditableGeoJsonLayer,
    DrawPolygonMode,
    ViewMode,
} from "@deck.gl-community/editable-layers";
import {
    XMarkIcon,
    MapPinIcon,
    PencilSquareIcon,
} from "@heroicons/react/24/solid";
import {
    AdminInfrastructureImportItemLocation,
    AdminInfrastructureImportItemShape,
    AdminInfrastructureImportItemPipelineShape,
} from "../../apiClient/generated";
import { emptyFeatureCollection } from "../../utils/geopatialUtils";
import { InfrastructureOnlyLayerColorMap } from "./constants";

interface GeoJSONInfrastructurePreviewMiniMapProps {
    geojson?: FeatureCollection<any>;
    onClick?: (infrastructureId?: string | number) => void;
    onHover?: (infrastructureId?: string | number) => void;
}

export const GeoJSONInfrastructurePreviewMiniMap = (
    props: GeoJSONInfrastructurePreviewMiniMapProps,
) => {
    const { flyTo } = useMap("InfrastructurePreviewMinimap");

    const mapLayers = useMemo(() => {
        if (!props.geojson || props.geojson.features.length < 1) {
            return [];
        }

        // Calculate center of data
        const center = turf.centerOfMass(props.geojson);
        if (center) {
            flyTo(
                center.geometry.coordinates[1],
                center.geometry.coordinates[0],
                12,
            );
        }

        // Add color properties to JSON
        const geojson = { ...props.geojson };
        geojson.features.forEach((item) => {
            const infraType = item.properties.infra_type || "";
            const colorMap = InfrastructureOnlyLayerColorMap[infraType];

            if (item.geometry.type === "Polygon") {
                item.properties.fillColor = [0, 0, 0, 0];
                item.properties.lineColor = colorMap?.fillColor || [
                    0, 0, 0, 255,
                ];
                item.properties.lineWidth = colorMap?.lineWidth || 3;
            } else {
                item.properties.fillColor = colorMap?.fillColor || [
                    0, 0, 0, 255,
                ];
                item.properties.lineColor = [255, 255, 255, 255];
                item.properties.lineWidth = colorMap?.lineWidth || 3;
            }
            item.properties.pointRadius = colorMap?.pointRadius || 8;
        });

        return [
            new GeoJsonLayer({
                id: "infrastructure",
                data: geojson,
                filled: true,
                visible: true,
                stroked: true,
                pickable: true,
                pointRadiusUnits: "pixels",
                lineWidthUnits: "pixels",
                getPointRadius: (item) => item.properties.pointRadius,
                getFillColor: (item) => item.properties.fillColor,
                autoHighlight: true,
                getLineWidth: (item) => item.properties.lineWidth,
                getLineColor: (item) => item.properties.lineColor,
            }),
        ];
    }, [props.geojson]);

    return (
        <div className="relative w-full h-full rounded overflow-hidden">
            <MapBase
                mapId={"InfrastructurePreviewMinimap"}
                layers={[mapLayers]}
                showScaleControl
                showZoomControl
                showMeasureControl
                onLeftClick={({ info }) => {
                    if (props.onClick && info.length > 0) {
                        if (info[0].layer.id === "infrastructure") {
                            props.onClick(info[0].object?.properties.id);
                        }
                    }
                }}
                onHover={(info) => {
                    if (props.onHover && info.layer) {
                        if (info.layer.id === "infrastructure") {
                            props.onHover(info.object?.properties.id);
                        }
                    }
                }}
                getTooltip={({ object }) => {
                    if (!object || !object.properties) {
                        return;
                    }

                    let str = "";
                    if (object.properties.facility_name) {
                        str = object.properties.facility_name;
                    }
                    if (object.properties.equipment_type) {
                        str = `${str}: ${object.properties.equipment_type}`;
                    }
                    if (object.properties.infra_type) {
                        str = `${str} (${object.properties.infra_type
                            .replace("_", " ")
                            .toLowerCase()})`;
                    }

                    return str;
                }}
            />

            {/* Map legend */}
            <div className="absolute right-2 top-2 z-0 text-2xl p-2 bg-white rounded-lg">
                <ul className="list-inside text-xs">
                    {Object.entries(InfrastructureOnlyLayerColorMap).map(
                        ([key, value]) => {
                            return (
                                <li className="flex items-center capitalize">
                                    <div
                                        className="rounded-full h-3 w-3 mr-1"
                                        style={{
                                            background: `rgb(${value.fillColor[0]},${value.fillColor[1]},${value.fillColor[2]})`,
                                        }}
                                    />
                                    {key.replace("_", " ").toLowerCase()}
                                </li>
                            );
                        },
                    )}
                    <li className="flex items-center capitalize">
                        <div className="rounded-full h-3 w-3 mr-1 bg-black" />
                        Unknown / unset
                    </li>
                </ul>
            </div>
        </div>
    );
};

interface DrawableMiniMapProps {
    point?: AdminInfrastructureImportItemLocation;
    onChangePoint: (newPoint: AdminInfrastructureImportItemLocation) => void;
    shape:
        | AdminInfrastructureImportItemShape
        | AdminInfrastructureImportItemPipelineShape;
    onChangeShape: (
        newShape:
            | AdminInfrastructureImportItemShape
            | AdminInfrastructureImportItemPipelineShape,
    ) => void;
}

const selectedFeatureIndexes: number[] = [];
export const DrawableMiniMap = (props: DrawableMiniMapProps) => {
    // Map state
    const { flyTo } = useMap("DrawableMinimap");

    // Drawing control state
    const [pickingPoint, setPickingPoint] = useState(false);

    // Drawing on map
    const [isDrawing, setIsDrawing] = useState(false);
    const drawLayer = useMemo(
        () =>
            new (EditableGeoJsonLayer as any)({
                id: "drawing-layer",
                data: emptyFeatureCollection,
                mode: isDrawing ? DrawPolygonMode : ViewMode,
                selectedFeatureIndexes,
                pickable: isDrawing,
                modeConfig: {
                    enableSnapping: true,
                },
                onEdit: ({ updatedData, editType }) => {
                    if (editType === "addFeature") {
                        setIsDrawing(false);
                        props.onChangeShape(updatedData.features[0].geometry);
                    }
                },
            }),
        [props.shape, isDrawing, setIsDrawing],
    );

    // Compute map layers
    const mapLayers = useMemo(() => {
        const layers = [];
        const geojson = {
            type: "FeatureCollection",
            features: [],
        };
        // If a point is defined
        if (props.point) {
            geojson.features.push({
                type: "Feature",
                geometry: props.point,
                properties: {},
            });
            flyTo(props.point.coordinates[1], props.point.coordinates[0]);
        }

        // If there's a shape
        if (props.shape) {
            geojson.features.push({
                type: "Feature",
                geometry: props.shape,
                properties: {},
            });

            // Shape without a point, center on shape
            if (!props.point) {
                const centroid = turf.centroid(props.shape);
                flyTo(centroid.coordinates[1], centroid.coordinates[0]);
            }
        }

        // Add layers to map
        layers.push(
            new GeoJsonLayer({
                id: "geometries",
                // FIXME: typing now included in deck.gl
                data: geojson as unknown as any,
                pointType: "circle",
                filled: true,
                visible: true,
                getPointRadius: 8,
                pointRadiusUnits: "pixels",
                lineWidthUnits: "pixels",
                stroked: true,
                pickable: true,
                getFillColor: [0, 0, 0],
                autoHighlight: true,
                getLineWidth: 3,
                getLineColor: [255, 255, 255, 255],
            }),
        );

        if (isDrawing) {
            layers.push(drawLayer);
        }

        return layers;
    }, [props.shape, props.point, isDrawing]);

    const handleLeftClick = ({ latitude, longitude }) => {
        if (pickingPoint) {
            props.onChangePoint({
                type: "Point",
                coordinates: [longitude, latitude],
            });
            setPickingPoint(false);
        }
    };

    return (
        <div className="relative w-full h-full rounded overflow-hidden">
            <MapBase
                mapId={"DrawableMinimap"}
                layers={mapLayers}
                showScaleControl
                showZoomControl
                showMeasureControl
                onLeftClick={handleLeftClick}
                cursor={isDrawing || pickingPoint ? "crosshair" : undefined}
            />
            <div className="bg-white absolute right-0 top-0 m-4 rounded-lg z-100">
                {!isDrawing && (
                    <button
                        className={`
                            flex items-center justify-center
                            rounded-lg w-10 h-10 bg-white hover:bg-slate-200
                        `}
                        onClick={() => {
                            setPickingPoint(!pickingPoint);
                        }}
                    >
                        {pickingPoint ? (
                            <XMarkIcon className="h-5 w-5 text-red-600" />
                        ) : (
                            <MapPinIcon className="h-5 w-5" />
                        )}
                    </button>
                )}
                {!pickingPoint && (
                    <button
                        className={`
                        flex items-center justify-center
                        rounded-lg w-10 h-10 bg-white hover:bg-slate-200
                    `}
                        onClick={() => {
                            setIsDrawing(!isDrawing);
                        }}
                        disabled={pickingPoint}
                    >
                        {isDrawing ? (
                            <XMarkIcon className="h-5 w-5 text-red-600" />
                        ) : (
                            <PencilSquareIcon className="h-5 w-5" />
                        )}
                    </button>
                )}
            </div>
        </div>
    );
};
