import * as d3 from "d3";
import { useRef, useState, useCallback, useEffect, useMemo } from "react";
import { SepEventStats } from "../../../apiClient/generated";
import { DateTime } from "luxon";

const addSwimLane = ({
    data,
    label,
    svg,
    startingYOffset,
    countdownDays,
    xScale,
    ticks,
    width,
    showCountDown,
    highlighted,
    thresholds,
}: {
    label: string;
    data: {
        date: Date;
        total: number;
        mine: number;
    }[];
    svg: any;
    xScale: any;
    ticks: any;
    startingYOffset: number;
    countdownDays: number;
    width: number;
    showCountDown: boolean;
    thresholds: {
        low: number;
        high: number;
    };
    highlighted?: Date;
}) => {
    const getBucketSize = (total: number) => {
        if (total <= thresholds.low) return 15;
        if (total <= thresholds.high) return 20;
        return 25;
    };

    // Add bg rectangle
    svg.append("rect")
        .attr("x", 0)
        .attr("y", startingYOffset + 4)
        .attr("width", width)
        .attr("height", 80)
        .attr("fill", "rgba(0, 44, 140, 0.02)")
        .style("stroke", "none")
        .each(function () {
            // Add top border
            svg.append("line")
                .attr("x1", 0)
                .attr("y1", startingYOffset + 4)
                .attr("x2", width)
                .attr("y2", startingYOffset + 4)
                .style("stroke", "#00000020")
                .style("stroke-width", 1);

            // Add bottom border
            svg.append("line")
                .attr("x1", 0)
                .attr("y1", startingYOffset + 84)
                .attr("x2", width)
                .attr("y2", startingYOffset + 84)
                .style("stroke", "#00000010")
                .style("stroke-width", 1);
        });

    if (showCountDown) {
        // Create countdown axis using the same xScale for perfect alignment
        const ticksToShow = ticks.slice(-countdownDays);
        const countdownAxis = d3
            .axisBottom(xScale)
            .tickSize(3)
            .tickPadding(15)
            .tickFormat((d: Date) => {
                const remainingDays =
                    countdownDays -
                    1 -
                    Math.ceil(
                        (ticks.slice(-1)[0].getTime() - d.getTime()) /
                            (1000 * 60 * 60 * 24),
                    );
                return String(remainingDays);
            })
            .tickValues(ticksToShow);

        // Shading after due date
        // Define the pattern in your SVG
        const pattern = svg
            .append("defs")
            .append("pattern")
            .attr("id", "crosshatch")
            .attr("patternUnits", "userSpaceOnUse")
            .attr("width", 8)
            .attr("height", 8);

        // Add first diagonal line (/)
        pattern
            .append("path")
            .attr("d", "M-2,2 l4,-4 M0,8 l8,-8 M6,10 l4,-4")
            .attr("stroke", "rgb(0, 44, 140)")
            .attr("stroke-width", 1)
            .attr("stroke-opacity", 0.2);

        // Update your rectangle to use the pattern
        svg.append("rect")
            .attr("x", xScale(ticksToShow[0]))
            .attr("y", startingYOffset + 4)
            .attr("width", width)
            .attr("height", 80)
            .attr("fill", "url(#crosshatch)")
            .style("stroke", "none");

        svg.append("g")
            .attr("transform", `translate(0,${startingYOffset + 75})`)
            .classed("text-gray-400", true)
            .call(countdownAxis)
            .call((g) => {
                g.select(".domain").remove();
                g.selectAll(".tick line").remove();
            })
            .selectAll("text")
            .style("font-size", "12px")
            .style("text-anchor", "middle")
            .style("fill", function (d) {
                const remainingDays =
                    countdownDays -
                    1 -
                    Math.ceil(
                        (ticks.slice(-1)[0].getTime() - d.getTime()) /
                            (1000 * 60 * 60 * 24),
                    );
                return remainingDays === 0 ? "red" : null;
            });

        // Add vertical red line at the last countdown day
        svg.append("line")
            .attr("x1", xScale(ticksToShow[0]))
            .attr("y1", startingYOffset + 4) // Same as top border
            .attr("x2", xScale(ticksToShow[0]))
            .attr("y2", startingYOffset + 84) // Same as bottom border
            .style("stroke", "red")
            .style("stroke-dasharray", "6")
            .style("stroke-width", 1);

        // Add y-axis label at the bottom
        svg.append("text")
            .classed("text-red-500", true)
            .attr("x", 8)
            .attr("y", startingYOffset + 100)
            .attr("text-anchor", "start")
            .style("font-size", "12px")
            .style("fill", "#9ca3af")
            .text("Days remaining");
    }

    // Bubble center
    let deadline: Date;
    if (countdownDays) {
        deadline = new Date(
            new Date().getTime() + 1000 * 60 * 60 * 24 * (14 - countdownDays),
        );
        deadline.setHours(0, 0, 0, 0);
    }

    // Compute the colors used in the bubbles
    let bubbleColorDeadlines = {
        warning: 3,
        alert: 2,
        critical: 1,
    };
    if (countdownDays > 5) {
        bubbleColorDeadlines = {
            warning: 5,
            alert: 3,
            critical: 1,
        };
    }

    const circleGroup = svg.append("g");
    circleGroup
        .selectAll("circle")
        .data(data)
        .enter()
        .filter((d) => d.total > 0)
        .append("circle")
        .attr("cx", (d) => {
            const date = new Date(d.date);
            date.setHours(0, 0, 0, 0);
            return xScale(date);
        })
        .attr("cy", startingYOffset + 40)
        .attr("r", (d) => getBucketSize(d.total))
        .attr("fill", (d) => {
            if (!showCountDown) {
                return "#D5DFF2";
            }

            if (!deadline) {
                return "white";
            }

            // Compute time to deadline
            d.date.setHours(0, 0, 0, 0);
            const daysToDeadLine =
                (d.date.getTime() - deadline.getTime()) / (1000 * 60 * 60 * 24);

            if (daysToDeadLine > bubbleColorDeadlines.warning) return "#E0E0E0";
            if (daysToDeadLine > bubbleColorDeadlines.alert) return "#F5E9C4";
            if (daysToDeadLine > bubbleColorDeadlines.critical)
                return "#FFA4A4";
            return "#FF8383";
        });

    circleGroup
        .append("g")
        .selectAll("circle")
        .data(data)
        .enter()
        .filter(
            (d) =>
                d.total > 0 &&
                highlighted &&
                highlighted.getDate() === d.date.getDate(),
        )
        .append("circle")
        .attr("cx", (d) => {
            const date = new Date(d.date);
            date.setHours(0, 0, 0, 0);
            return xScale(date);
        })
        .attr("cy", startingYOffset + 40)
        .attr("r", (d) => getBucketSize(d.total) + 3)
        .attr("fill", "transparent")
        .attr("stroke", "#1677FF")
        .attr("stroke-width", 2);

    const textGroup = svg.append("g");
    textGroup
        .selectAll("text")
        .data(data)
        .enter()
        .filter((d) => d.total > 0)
        .append("text")
        .attr("x", (d) => {
            const date = new Date(d.date);
            date.setHours(0, 0, 0, 0);
            return xScale(date);
        })
        .attr("y", startingYOffset + 41)
        .attr("text-anchor", "middle")
        .attr("dominant-baseline", "middle")
        .style("font-size", "12px")
        .style("fill", "#1F1F1F")
        .text((d) => d.total);

    // Add counts and labels
    // Add y-axis label at the bottom
    svg.append("text")
        .classed("text-red-500", true)
        .attr("x", 8)
        .attr("y", startingYOffset + 25)
        .attr("text-anchor", "start")
        .style("font-size", "14px")
        .style("fill", "#1F1F1F")
        .text(label);

    // Add y-axis label at the bottom
    const totalEvents = data.reduce((acc, item) => {
        acc += item.total;
        return acc;
    }, 0);
    svg.append("text")
        .classed("text-red-500", true)
        .attr("x", 8)
        .attr("y", startingYOffset + 50)
        .attr("text-anchor", "start")
        .style("font-size", "20px")
        .style("fill", "#1F1F1F")
        .text(totalEvents);
};

export const SEPTimelinePlot = ({ data }: { data: SepEventStats[] }) => {
    const svgRef = useRef();
    const containerRef = useRef<HTMLDivElement>();
    const [dimensions, setDimensions] = useState({ width: 0 });
    const [hoverDate, setHoverDate] = useState<Date>(null);

    // Function to update dimensions
    const updateDimensions = useCallback(() => {
        if (containerRef.current) {
            setDimensions({
                width: containerRef.current.clientWidth,
            });
        }
    }, []);

    // Effect for handling resize
    useEffect(() => {
        // Initial dimensions
        updateDimensions();

        // Add event listener for window resize
        window.addEventListener("resize", updateDimensions);

        // Create ResizeObserver for component-specific resizes
        const resizeObserver = new ResizeObserver(updateDimensions);
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        // Cleanup
        return () => {
            window.removeEventListener("resize", updateDimensions);
            resizeObserver.disconnect();
        };
    }, [updateDimensions]);

    /**
     * Preprocess data:
     * 1. Only show data whitin valid dates.
     * 2. Group overdue values into a single day (yesterday).
     */
    const events = useMemo((): SepEventStats[] => {
        const today = DateTime.now().setZone("utc").endOf("day");
        const events: SepEventStats[] = [];
        const overdueEvents: SepEventStats = {
            dueDate: today
                .minus({ days: 1 })
                .set({ hour: 12, minute: 0, second: 0 })
                .toJSDate(),
            completed: 0,
            inProgress: 0,
            notStartedCount: 0,
            myCompleted: 0,
            myInProgress: 0,
            myNotStartedCount: 0,
        };

        for (const evt of data) {
            // Well, this is not fun.
            // FIXME: typescript-fetch coerces ISO-8601 date strings into
            // the user's local timezone when converting to JS dates.
            // So the workaround in in the lines below, I'm not 100%
            // sure it'll work on all cases.
            // Solutions to this:
            // 1. Make DRF always output Localized ISO *DateTimes* (needs
            //    careful testing so it doesn't break other things).
            // 2. Switch to typescript-axios and handle date serialization
            //    on our side.
            // Implementing both is also an option, it's the best outcome.
            const dueDate = DateTime.fromJSDate(evt.dueDate)
                .setZone("utc")
                .endOf("day");
            if (dueDate >= today.endOf("day")) {
                events.push({
                    ...evt,
                    dueDate: dueDate.toJSDate(),
                });
            } else {
                overdueEvents.completed += evt.completed;
                overdueEvents.inProgress += evt.inProgress;
                overdueEvents.notStartedCount += evt.notStartedCount;
                overdueEvents.myCompleted += evt.myCompleted;
                overdueEvents.myInProgress += evt.myInProgress;
                overdueEvents.myNotStartedCount += evt.myNotStartedCount;
            }
        }

        if (
            overdueEvents.completed +
                overdueEvents.notStartedCount +
                overdueEvents.inProgress >
            0
        ) {
            events.push(overdueEvents);
        }

        return events;
    }, [data]);

    /**
     * Compute thresholds for bubble sizes.
     */
    const thresholds = useMemo(() => {
        // Collect all unique values for each status separately
        const uniqueTotals = new Set(
            [
                ...events.map((event) => event.completed),
                ...events.map((event) => event.inProgress),
                ...events.map((event) => event.notStartedCount),
            ].filter((total) => total > 0),
        );

        // Convert to sorted array
        const sortedTotals = Array.from(uniqueTotals).sort((a, b) => a - b);

        // Otherwise calculate thresholds at 33rd and 66th percentiles
        const lowThreshold =
            sortedTotals[Math.floor(sortedTotals.length * 0.33)];
        const highThreshold =
            sortedTotals[Math.floor(sortedTotals.length * 0.66)];

        return {
            low: lowThreshold || 1,
            high: highThreshold || 2,
        };
    }, [events]);

    useEffect(() => {
        const svg = d3.select(svgRef.current);
        const container = d3.select(containerRef.current);
        const width = container.node().clientWidth;

        // Plot margins
        const margin = { top: 40, right: 100, bottom: 0, left: 200 };

        // Clear previous content
        svg.selectAll("*").remove();

        // Timeline with reversed date range
        const dateRange = [
            new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 1),
            new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 13),
        ];

        const xScale = d3
            .scaleTime()
            .domain(dateRange)
            .range([width - margin.right, margin.left])
            .nice();

        const computeTicks = () => {
            const [start, end] = xScale.domain();
            const days = Math.ceil(
                (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24),
            );
            return Array.from(
                { length: days + 1 },
                (_, i) => new Date(start.getTime() + i * 24 * 60 * 60 * 1000),
            );
        };
        const ticks = computeTicks();

        const xAxis = d3
            .axisTop(xScale)
            .tickSize(3)
            .tickPadding(15)
            .tickFormat((d: Date) => {
                const day = d3.timeFormat("%d")(d as any);
                const month = d3.timeFormat("%b")(d as any);
                if (d.getDate() === new Date().getDate()) {
                    return "Today";
                }
                if (d.getTime() < new Date().getTime()) {
                    return "Overdue";
                }
                return `${day}/${month}`;
            })
            .tickValues(ticks);

        const investigationXAxis = d3
            .axisTop(xScale)
            .tickSize(3)
            .tickPadding(15)
            .tickFormat((d: Date) => {
                const dateTime = DateTime.fromJSDate(d)
                    .startOf("day")
                    .minus({ days: 10 });
                const dueDate = DateTime.now().startOf("day");

                if (dateTime.equals(dueDate)) {
                    return "Today";
                }
                if (dateTime.equals(dueDate.minus({ days: 1 }))) {
                    return "Overdue";
                }
                if (dateTime < dueDate.minus({ days: 1 })) {
                    return "";
                }

                return dateTime.toFormat("d/MMM");
            })
            .tickValues(ticks);

        // Add highlight bars
        svg.selectAll(".highlight-bar")
            .data(ticks)
            .join("rect")
            .attr("class", "highlight-bar")
            .attr("x", (d) => xScale(d) - 10)
            .attr("y", 45)
            .attr("width", 20)
            .attr("height", 390)
            .transition()
            .duration(150)
            .attr("fill", (d) => {
                return hoverDate && d.getTime() === hoverDate.getTime()
                    ? "rgba(0, 44, 140, 0.05)"
                    : "transparent";
            });

        // Append x-axis and style it
        svg.append("g")
            .attr("transform", `translate(0,${margin.top})`)
            .classed("text-gray-400", true)
            .call(investigationXAxis)
            .call((g) => {
                g.select(".domain").remove();
                g.selectAll(".tick line").remove();
            })
            .selectAll("text")
            .style("font-size", "12px")
            .style("text-anchor", "middle")
            .each(function (d: Date) {
                const highlighted =
                    hoverDate && d.getTime() === hoverDate.getTime();
                const text = d3.select(this);
                const [day, month] = text.text().split("/");
                text.text("")
                    .classed(
                        highlighted
                            ? "text-ae-blue-550 font-bold"
                            : "text-gray-400",
                        true,
                    )
                    .append("tspan")
                    .attr("x", 0)
                    .attr("dy", !month ? "0.3em" : "-0.3em")
                    .text(day);

                text.append("tspan")
                    .classed(
                        highlighted
                            ? "text-ae-blue-550 font-bold"
                            : "text-gray-400",
                        true,
                    )
                    .attr("x", 0)
                    .attr("dy", "1.2em")
                    .text(month);
            });

        // Add y-axis label at the bottom
        svg.append("text")
            .classed("text-red-500", true)
            .attr("x", 8)
            .attr("y", 34)
            .attr("text-anchor", "start")
            .style("font-size", "12px")
            .style("fill", "#9ca3af")
            .text("Start investigation");

        // Add first swimlane with data for new / unassigned EPA events
        addSwimLane({
            data: events.map((i) => ({
                date: i.dueDate,
                mine: i.myNotStartedCount,
                total: i.notStartedCount,
            })),
            label: "Not Started",
            svg,
            xScale,
            ticks,
            width,
            startingYOffset: 40,
            countdownDays: 5,
            showCountDown: true,
            highlighted: hoverDate,
            thresholds,
        });

        // Add second timeline
        svg.append("text")
            .classed("text-red-500", true)
            .attr("x", 8)
            .attr("y", 195)
            .attr("text-anchor", "start")
            .style("font-size", "12px")
            .style("fill", "#9ca3af")
            .text("Submit Report");

        svg.append("g")
            .attr("transform", "translate(0,200)")
            .classed("text-gray-400", true)
            .call(xAxis)
            .call((g) => {
                g.select(".domain").remove();
                g.selectAll(".tick line").remove();
            })
            .selectAll("text")
            .style("font-size", "12px")
            .style("text-anchor", "middle")
            .each(function (d: Date) {
                const highlighted =
                    hoverDate && d.getTime() === hoverDate.getTime();
                const text = d3.select(this);
                const [day, month] = text.text().split("/");
                text.text("")
                    .classed(
                        highlighted
                            ? "text-ae-blue-550 font-bold"
                            : "text-gray-400",
                        true,
                    )
                    .append("tspan")
                    .attr("x", 0)
                    .attr("dy", !month ? "0.3em" : "-0.3em")
                    .text(day);

                text.append("tspan")
                    .classed(
                        highlighted
                            ? "text-ae-blue-550 font-bold"
                            : "text-gray-400",
                        true,
                    )
                    .attr("x", 0)
                    .attr("dy", "1.2em")
                    .text(month);
            });

        // Add second swimlane
        addSwimLane({
            data: events.map((i) => ({
                date: i.dueDate,
                mine: i.myInProgress,
                total: i.inProgress,
            })),
            label: "In progress",
            svg,
            xScale,
            ticks,
            width,
            startingYOffset: 200,
            countdownDays: 15,
            showCountDown: true,
            highlighted: hoverDate,
            thresholds,
        });

        // Add third swimlane
        addSwimLane({
            data: events.map((i) => ({
                date: i.dueDate,
                mine: i.myCompleted,
                total: i.completed,
            })),
            label: "Completed",
            svg,
            xScale,
            ticks,
            width,
            startingYOffset: 320,
            countdownDays: 0,
            showCountDown: false,
            highlighted: hoverDate,
            thresholds,
        });

        // Add event totals
        svg.append("line")
            .attr("x1", 0)
            .attr("y1", 405)
            .attr("x2", width)
            .attr("y2", 405)
            .classed("stroke-gray-300", true)
            .style("stroke-width", 1);

        // Add y-axis label at the bottom
        svg.append("text")
            .classed("text-red-500", true)
            .attr("x", 8)
            .attr("y", 425)
            .attr("text-anchor", "start")
            .style("font-size", "14px")
            .style("font-weight", "bold")
            .style("fill", "#1F1F1F")
            .text("Totals");

        // Add total number of emissions at the bottoms
        const totalEvents = events.reduce((acc, item) => {
            acc += item.completed + item.inProgress + item.notStartedCount;
            return acc;
        }, 0);
        svg.append("text")
            .classed("text-red-500", true)
            .attr("x", 8)
            .attr("y", 450)
            .attr("text-anchor", "start")
            .style("font-size", "20px")
            .style("font-weight", "bold")
            .style("fill", "#1F1F1F")
            .text(totalEvents);

        // First create an array of all dates with their totals, including 0 for dates with no data
        const allDatesWithTotals = ticks.map((date) => {
            const matchingData = events.find((d) => {
                const dDate = new Date(d.dueDate);
                return (
                    dDate.getDate() === date.getDate() &&
                    dDate.getMonth() === date.getMonth() &&
                    dDate.getFullYear() === date.getFullYear()
                );
            });

            return {
                date: date,
                total: matchingData
                    ? matchingData.completed +
                      matchingData.inProgress +
                      matchingData.notStartedCount
                    : 0,
            };
        });

        // Add circles for totals that only appear on hover
        const totalsCircleGroup = svg.append("g");
        totalsCircleGroup
            .selectAll("circle")
            .data(allDatesWithTotals)
            .enter()
            .append("circle")
            .attr("cx", (d) => xScale(d.date))
            .attr("cy", 435)
            .attr("r", 15)
            .attr("fill", "white")
            .attr("stroke", "#1677FF")
            .attr("stroke-width", 2)
            .style("opacity", (d) =>
                hoverDate && d.date.getTime() === hoverDate.getTime() ? 1 : 0,
            );

        // Add text labels for the totals using the complete date range
        svg.selectAll(".day-total-labels")
            .data(allDatesWithTotals)
            .join("text")
            .attr("x", (d) => xScale(d.date))
            .attr("y", 440)
            .attr("text-anchor", "middle")
            .style("font-size", "12px")
            .text((d) => d.total);

        // Un-highlight when not hovering over a specific date
        svg.append("rect")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", width)
            .attr("height", 520)
            .attr("fill", "transparent")
            .style("stroke", "none")
            .on("mouseover", () => {
                setHoverDate(null);
            });

        // Add highlight bars
        svg.selectAll(".highligth-hitbox")
            .data(ticks)
            .enter()
            .append("rect")
            .attr("class", "tick-line")
            .attr("x", (d) => xScale(d) - 25)
            .attr("y", 0)
            .attr("width", 50)
            .attr("height", 520)
            .attr("fill", "transparent")
            .style("cursor", "pointer")
            .on("mouseover", (event, d) => {
                if (hoverDate !== d) {
                    setHoverDate(d);
                }
            });
    }, [dimensions, hoverDate, events, thresholds]);

    return (
        <div ref={containerRef} className="w-full h-[470px]">
            <svg ref={svgRef} style={{ width: "100%", height: "100%" }}></svg>
        </div>
    );
};
