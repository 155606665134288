import { ControlContainer } from "./common";

function getScaleBarLength(zoomLevel: number, latitude: number) {
    // Convert the zoom + latitude to meters per pixel
    const earthCircumference = 40075016.686;
    const latitudeRadians = latitude * (Math.PI / 180);
    const metersPerPixel =
        (earthCircumference * Math.cos(latitudeRadians)) /
        Math.pow(2, zoomLevel + 8);

    // Bar indicator length
    let barLengthMeters = metersPerPixel * 15;

    // Round barLengthMeters to a nice value (1, 2, 5, 10, etc.)
    const niceValues = [1, 2, 5, 10];
    const magnitude = Math.pow(10, Math.floor(Math.log10(barLengthMeters)));
    barLengthMeters =
        niceValues.find((value) => value * magnitude >= barLengthMeters)! *
        magnitude;

    // Convert barLengthMeters to pixels
    // I had to manually tune this value to match the actual scale, it was off by a factor of 2.
    const barLengthPixels = (barLengthMeters * 2) / metersPerPixel;
    return {
        barLengthMeters,
        barLengthPixels,
    };
}

interface MapScaleProps {
    latitude: number;
    zoom: number;
}

export const MapScale = (props: MapScaleProps) => {
    const { barLengthMeters, barLengthPixels } = getScaleBarLength(
        props.zoom,
        props.latitude,
    );
    const scaleLabel =
        barLengthMeters >= 1000
            ? `${barLengthMeters / 1000} km`
            : `${barLengthMeters} m`;

    return (
        <ControlContainer className="h-8 p-1 bg-opacity-100 pointer-events-auto">
            <div className="py-1 px-2 mx-auto flex gap-2 items-center justify-center text-sm bg-white">
                <div
                    className="border-b border-x h-1.5 border-black border-t-black"
                    style={{ width: `${barLengthPixels}px` }}
                />
                {scaleLabel}
            </div>
        </ControlContainer>
    );
};
