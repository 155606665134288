import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ControlButton } from "../ui/MapControls";
import { faRuler } from "@fortawesome/pro-light-svg-icons";
import { useMemo, useState } from "react";
import {
    ClickEvent,
    EditableGeoJsonLayer,
    FeatureCollection,
    ModeProps,
} from "@deck.gl-community/editable-layers";
import { MeasureDistanceMode } from "@deck.gl-community/editable-layers";

const iconMapping = {
    marker: {
        x: 0,
        y: 0,
        width: 25,
        height: 25,
        mask: false,
    },
};

function generateCrosshair() {
    const canvas = document.createElement("canvas");
    canvas.width = 25;
    canvas.height = 25;
    const ctx = canvas.getContext("2d");

    // Draw white background cross
    ctx.strokeStyle = "white";
    ctx.lineWidth = 5;
    ctx.beginPath();
    ctx.moveTo(0, 12.5);
    ctx.lineTo(25, 12.5);
    ctx.moveTo(12.5, 0);
    ctx.lineTo(12.5, 25);
    ctx.stroke();

    // Draw blue center cross
    ctx.strokeStyle = "black";
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.moveTo(1, 12.5);
    ctx.lineTo(24, 12.5);
    ctx.moveTo(12.5, 1);
    ctx.lineTo(12.5, 24);
    ctx.stroke();

    return canvas;
}

export class TwoPointMeasureMode extends MeasureDistanceMode {
    handleClick(event: ClickEvent, props: ModeProps<FeatureCollection>) {
        // Call parent class to handle click logic.
        super.handleClick(event, props);

        // Retrieve click sequence
        const clickSequence = this.getClickSequence();

        // If there's 2 points already, finish measuring session.
        if (clickSequence.length === 2) {
            this._isMeasuringSessionFinished = true;
        }
    }

    handleKeyUp(event: KeyboardEvent, props: ModeProps<FeatureCollection>) {
        event.stopPropagation();
        const { key } = event;

        if (key === "Escape") {
            this.resetClickSequence();
            this._currentTooltips = [];
            props.onUpdateCursor("cell");
        }
    }
}

export const useMapMeasuring = ({ enabled }: { enabled: boolean }) => {
    const [isMeasuring, setIsMeasuring] = useState(false);
    const measureLayers = useMemo(() => {
        if (!isMeasuring) {
            return [];
        }
        return [
            new (EditableGeoJsonLayer as any)({
                id: "measuring-layer",
                mode: TwoPointMeasureMode,
                selectedFeatureIndexes: [],
                pickable: true,
                _subLayerProps: {
                    // Guides: measurement lines and points
                    guides: {
                        getLineColor: [22, 119, 255, 255],
                        pointType: "icon",
                        getIconSize: 20,
                        getIcon: () => "marker",
                        iconAtlas: generateCrosshair(),
                        iconMapping,
                    },
                    // Distance markers
                    tooltips: {
                        billboard: true,
                        background: true,
                        backgroundPadding: [5, 2],
                        getBackgroundColor: [255, 255, 255, 180],
                        getBorderColor: [0, 0, 0, 80],
                        getBorderWidth: 1,
                        getSize: 13,
                    },
                },
                modeConfig: {
                    enableSnapping: true,
                    centerTooltipsOnLine: true,
                    formatTooltip: (distance) =>
                        `${(parseFloat(distance) * 1000).toFixed(1)} m`,
                },
            }),
        ];
    }, [isMeasuring]);
    return {
        isMeasuring,
        toggleMeasuring: () => enabled && setIsMeasuring(!isMeasuring),
        measureLayers,
    };
};

interface MeasureControlProps {
    measuring: boolean;
    toggleMeasure: () => void;
}

export const MeasureControlBase = (props: MeasureControlProps) => (
    <div className="flex flex-col gap-3">
        <ControlButton
            className="w-8 rounded"
            selected={props.measuring}
            onClick={(e) => {
                e.stopPropagation();
                props.toggleMeasure();
            }}
        >
            <FontAwesomeIcon icon={faRuler} className="h-4" />
        </ControlButton>
    </div>
);
