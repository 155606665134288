export const cleanFilters = <Filters>(filters: {
    [key: string]: any;
}): {
    cleanFilterValues: Filters;
    shortCircuit: boolean;
} => {
    let shortCircuit = false;
    // If a list filter is empty, short circuit and return.
    Object.values(filters).forEach((f) => {
        if (Array.isArray(f) && f.length === 0) {
            shortCircuit = true;
        }
    });

    // Remove any "all" filters -> same as selecting all items
    const cleanFilterValues: any = Object.entries(filters).reduce(
        (acc, [key, value]) => {
            if (value === "all") {
                return acc;
            }
            return { ...acc, [key]: value };
        },
        {} as Filters,
    );

    return {
        cleanFilterValues,
        shortCircuit,
    };
};
