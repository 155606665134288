import { useState } from "react";
import { ControlButton } from "../../ui/MapControls";
import { useMapData } from "../../hooks/mapDataAndFilters";
import { createPortal } from "react-dom";

import {
    faCircleHalfStroke,
    faEye,
    faEyeSlash,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomSwitch } from "../../../../ui/CustomSwitch";

interface PlumeOpacityPanelProps {
    plumeOpacity: number;
    showPlumes: boolean;
    oldOpacity: number;
    onOpacityChange: (value: number) => void;
    onToggleVisibility: (oldOpacity: number) => void;
    onShowPlumesChange: () => void;
    className?: string;
}

export const PlumeOpacityPanel = ({
    plumeOpacity,
    showPlumes,
    oldOpacity,
    onOpacityChange,
    onToggleVisibility,
    onShowPlumesChange,
    className = "",
}: PlumeOpacityPanelProps) => (
    <div
        className={`flex gap-3 bg-white rounded px-4 py-3 text-sm whitespace-nowrap items-center ${className}`}
    >
        Plume opacity
        <button
            className="px-1 rounded hover:bg-gray-200"
            onClick={() => onToggleVisibility(oldOpacity)}
            type="button"
        >
            <FontAwesomeIcon
                icon={plumeOpacity ? faEye : faEyeSlash}
                className="w-4"
            />
        </button>
        <input
            type="range"
            className="w-full min-w-20"
            value={plumeOpacity}
            min={0}
            step={0.01}
            max={1}
            onChange={(e) => onOpacityChange(e.target.valueAsNumber)}
        />
        {(plumeOpacity * 100).toFixed(0)} %
        <div className="flex w-content items-center gap-3 ml-4">
            <CustomSwitch
                checked={showPlumes}
                onChange={onShowPlumesChange}
                size="xs"
            />
            <div className="w-24">
                {!showPlumes ? "Outlines only" : "Outlines/plumes"}
            </div>
        </div>
    </div>
);

export const PlumeControl = ({
    containerRef,
}: {
    containerRef: React.RefObject<HTMLDivElement>;
}) => {
    const {
        mapSettings: { plumeOpacity, showPlumes },
        setMapDataState,
    } = useMapData("mainMap");
    const [showOpacityControl, setShowOpacityControl] = useState(false);
    const [oldOpacity, setOldOpacity] = useState(0);

    const handleOpacityChange = (value: number) => {
        setMapDataState((ds) => ({
            ...ds,
            mapSettings: {
                ...ds.mapSettings,
                plumeOpacity: value,
            },
        }));
    };

    const handleToggleVisibility = (oldOpacityValue: number) => {
        setMapDataState((ds) => ({
            ...ds,
            mapSettings: {
                ...ds.mapSettings,
                plumeOpacity: oldOpacityValue,
            },
        }));
        setOldOpacity(plumeOpacity);
    };

    const handleShowPlumesChange = () => {
        setMapDataState((ds) => ({
            ...ds,
            mapSettings: {
                ...ds.mapSettings,
                showPlumes: !showPlumes,
            },
        }));
    };

    return (
        <>
            <ControlButton
                className="gap-2 px-3 text-sm rounded text-nowrap border border-ae-gray-250"
                selected={showOpacityControl}
                onClick={() => setShowOpacityControl(!showOpacityControl)}
            >
                <FontAwesomeIcon icon={faCircleHalfStroke} className="w-4" />
                Plume opacity
            </ControlButton>
            {showOpacityControl &&
                containerRef.current &&
                createPortal(
                    <PlumeOpacityPanel
                        className="w-full mt-2"
                        plumeOpacity={plumeOpacity}
                        showPlumes={showPlumes}
                        oldOpacity={oldOpacity}
                        onOpacityChange={handleOpacityChange}
                        onToggleVisibility={handleToggleVisibility}
                        onShowPlumesChange={handleShowPlumesChange}
                    />,
                    containerRef.current,
                    "plumeOpacityControl",
                )}
        </>
    );
};
