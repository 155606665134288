import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEpaSepApiClient } from "../../../hooks";
import { FullscreenModalBase } from "../../../ui/Modals";
import {
    FormSection,
    SubmitToCdxNotice,
    EpaMatchedInfo,
    CertifyNotice,
} from "./ui";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";
import { CoordinatesField } from "../../../ui/GenericFields";
import { PrimaryButton, SecondaryButton } from "../../../ui/Buttons";
import { useEpaForm } from "./form";
import { EpaForm, EpaOperatorNotification } from "../../../apiClient/generated";
import { LoadingIndicator } from "../../MapV2/ui/LoadingIndicator";
import {
    CheckboxField,
    DateField,
    FacilityInformationIdField,
    TextArea,
    TextField,
    TimeField,
    TimezonePicker,
    YesNoQuestion,
} from "./fields";
import { FormProvider } from "react-hook-form";
import { EpaFormUploadField } from "./FileUploads";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronsDown,
    faChevronsUp,
    faX,
} from "@fortawesome/pro-light-svg-icons";
import { EditableUserCell } from "../../../ui/Table/CustomCells/EditableUserCell";
import { HelperTooltip } from "../../../ui/Tooltips";
import { EpaMinimap } from "./map";
import { atom, useAtom } from "jotai";
import { computeNearestSite } from "./utils";

const isDirtyAtom = atom(false);

interface EpaSepNotificationInformationProps {
    epaNotificationId: string;
    expanded: boolean;
}

const EpaSepNotificationInformation = (
    props: EpaSepNotificationInformationProps,
) => {
    const queryClient = useQueryClient();
    const apiClient = useEpaSepApiClient();
    const notificationQuery = useQuery({
        queryKey: ["epaSepNotification", props.epaNotificationId],
        queryFn: async () => {
            return await apiClient.epaSepNotificationsRetrieve({
                id: props.epaNotificationId,
            });
        },
        refetchOnWindowFocus: false,
        enabled: !!props.epaNotificationId,
        staleTime: 0,
    });

    const data = useMemo((): {
        [key: string]: string | ReactNode;
    } => {
        if (!notificationQuery.data) {
            return {};
        }

        const { site, distance } = computeNearestSite(notificationQuery.data);
        const dt = notificationQuery.data;
        const deadline = new Date(
            dt.notification?.notificationDate?.getTime() +
                1000 * 60 * 60 * 24 * 15,
        );
        const diffInDays = Math.floor(
            (deadline.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24),
        );
        const d = {
            "Notified by EPA": (
                <div className="flex items-center gap-1">
                    {dt.notificationReason === "EPA_NOTIFICATION"
                        ? "Yes"
                        : "No"}
                    <HelperTooltip>
                        <div className="font-normal text-sm max-w-52">
                            <p className="text-pretty mb-2">
                                Yes means your company is required to complete
                                investigation within 15 days of notification
                                date.
                            </p>
                            <p className="text-pretty">
                                No means you have not yet officially been
                                notified by EPA but this event may be at your
                                facility.
                            </p>
                        </div>
                    </HelperTooltip>
                </div>
            ),

            Assignee: (
                <EditableUserCell
                    assignee={{
                        id: dt.assignee,
                        name: dt.assigneeName,
                    }}
                    save={async (userId) => {
                        await apiClient.epaSepNotificationsPartialUpdate({
                            id: props.epaNotificationId,
                            patchedEpaOperatorNotificationRequest: {
                                assignee: userId,
                            },
                        });
                        queryClient.invalidateQueries({
                            queryKey: [
                                "epaSepNotification",
                                props.epaNotificationId,
                            ],
                        });
                        queryClient.invalidateQueries({
                            queryKey: ["EpaSepStats"],
                        });
                        queryClient.invalidateQueries({
                            queryKey: ["EpaSepStatsBar"],
                        });
                    }}
                />
            ),
            "Notification Report ID": dt.notification.notificationReportId,
            "EPA Identified site": dt.epaIdentifiedSite || "-",
            "Date of notification": DateTime.fromJSDate(
                dt.notification?.notificationDate,
            )
                .setZone("utc")
                .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY),
            "Nearest site in AERSHED": site?.siteName || "-",
            "Date of detection": DateTime.fromJSDate(
                dt.notification.detectionDate,
            )
                .setZone("utc")
                .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY),
            Distance: `${distance.toFixed(2)} m` || "-",
            "Latitude/Longitude": (
                <CoordinatesField
                    coordinates={dt.notification.location.coordinates}
                    allowCopy
                />
            ),
            "Due Date": DateTime.fromJSDate(dt.notification?.dueDate)
                .setZone("utc")
                .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY),
            "Emission rate": `${dt.notification.detectedRate / 1000} kg/h`,
            "Time remaining":
                dt.notificationReason === "EPA_NOTIFICATION" ? (
                    diffInDays >= 0 ? (
                        `${diffInDays} days`
                    ) : (
                        <span className="text-red-500">Overdue</span>
                    )
                ) : (
                    "-"
                ),
            "Provider name": dt.notification.providerName,
            "Notifier name": dt.notification.notifierName,
        };
        if (props.expanded) {
            d["Wind Speed"] = `${dt.notification?.windSpeed || "-"} m/s`;
            d["Wind Direction"] =
                `${dt.notification?.windDirection || "-"} degrees`;
            d["Temperature"] = `${dt.notification?.temperature || "-"} F`;
            d["Humidity"] = `${dt.notification?.windSpeed || "-"} g/kg`;
            for (const [key, value] of Object.entries(
                dt.notification.metadata || {},
            )) {
                d[`${key} *`] = value;
            }
        }
        return d;
    }, [
        notificationQuery.data,
        props.expanded,
        apiClient,
        props.epaNotificationId,
        queryClient,
    ]);

    if (!notificationQuery.data) {
        return null;
    }

    return (
        <div className="grid grid-cols-4 gap-y-2 gap-x-4 text-sm">
            {Object.entries(data).map(([key, value]) => (
                <div key={key} className="contents">
                    <div className="text-gray-600">{key}</div>
                    <div className="font-semibold line-clamp-1">{value}</div>
                </div>
            ))}
        </div>
    );
};

interface EpaSepReportFormProps {
    epaForm: EpaForm;
    epaNotification: EpaOperatorNotification;
    onClose: () => void;
    readOnly: boolean;
}

const EpaSepReportForm = (props: EpaSepReportFormProps) => {
    const { form, submit } = useEpaForm(props.epaForm, props.onClose);
    const [, setIsDirty] = useAtom(isDirtyAtom);

    useEffect(() => {
        setIsDirty(form.formState.isDirty);
    }, [form.formState.isDirty, setIsDirty]);

    return (
        <FormProvider {...form}>
            <form onSubmit={submit}>
                {props.epaNotification.notificationReason ===
                "EPA_NOTIFICATION" ? (
                    <SubmitToCdxNotice />
                ) : (
                    <EpaMatchedInfo />
                )}
                <EpaMinimap
                    key={props.epaNotification.id}
                    epaNotification={props.epaNotification}
                />
                <FormSection>
                    <div className="w-full grid grid-cols-8 gap-12">
                        <p className="col-span-6 text-pretty">
                            Are you the owner, operator, or responsible official
                            (when applicable) of an oil and natural gas facility
                            (e.g., an individual well site, centralized
                            production facility, natural gas processing plant,
                            or compression station) located within 50 meters of
                            the latitude and longitude provided in the EPA
                            notification?
                        </p>
                        <YesNoQuestion
                            fieldName="responsibleForEmission"
                            disabled={props.readOnly}
                        />
                    </div>
                </FormSection>
                <FormSection
                    title="Facility information"
                    description="If the EPA ID# or API Well ID# in the notification was incorrect, please input the correct ID in the field below."
                >
                    <div className="w-full grid grid-cols-[3fr_2fr] gap-x-12 gap-y-4">
                        <FacilityInformationIdField disabled={props.readOnly} />
                        <p>Facility name*</p>
                        <TextField
                            fieldName="facilityName"
                            disabled={props.readOnly}
                        />
                        <p>Address/City/State/Zip*</p>
                        <TextField
                            fieldName="address"
                            disabled={props.readOnly}
                        />
                    </div>
                </FormSection>
                <FormSection
                    title="Demonstrable error"
                    description="Provide evidence and a statement if you believe the notification contains a clear error."
                >
                    <div className="w-full grid grid-cols-[3fr_2fr] gap-x-12 gap-y-4">
                        <p>
                            Do you assert a demonstrable error in the
                            notification?
                        </p>
                        <YesNoQuestion
                            fieldName="assertDemonstrableError"
                            disabled={props.readOnly}
                        />
                        <p>Upload evidence</p>
                        <EpaFormUploadField
                            uploadType="DEMONSTRABLE_ERROR"
                            form={props.epaForm}
                        />
                        <p>
                            Statement of Demonstrable Error (required if
                            asserting a demonstrable error)
                        </p>
                        <TextArea
                            fieldName="statementDemonstrableError"
                            disabled={props.readOnly}
                        />
                    </div>
                </FormSection>
                <FormSection
                    title="Applicability and identification"
                    description="Provide evidence and a statement if you believe the notification contains a clear error."
                >
                    <div className="w-full grid grid-cols-[3fr_2fr] gap-x-12 gap-y-4">
                        <p className="text-balanced">
                            Is there an affected facility or associated
                            equipment subject to regulation under NSPS OOOO,
                            NSPS OOOOa, NSPS OOOOb, and/or a State or Federal
                            Plan implementing OOOOc at this oil and gas
                            facility?*
                        </p>
                        <YesNoQuestion
                            fieldName="affectedFacility"
                            disabled={props.readOnly}
                        />
                        <p>
                            Did you identify the source of the super emitter
                            event?*
                        </p>
                        <YesNoQuestion
                            fieldName="identifiedSource"
                            disabled={props.readOnly}
                        />
                    </div>
                </FormSection>
                <FormSection
                    title="Search for source"
                    description="Provide details about the applicable investigation."
                >
                    <p className="mb-2">
                        Did you conduct the applicable investigation listed in
                        (d)(2)(i)-(v)?
                    </p>
                    <CheckboxField
                        fieldName="investigationSteps"
                        nestedIndex={0}
                        label="(i) Review any maintenance activities or process activities"
                        disabled={props.readOnly}
                    />
                    <CheckboxField
                        fieldName="investigationSteps"
                        nestedIndex={1}
                        label="(ii) Review all monitoring data from control devices."
                        disabled={props.readOnly}
                    />
                    <CheckboxField
                        fieldName="investigationSteps"
                        nestedIndex={2}
                        label="(iii) Review the results of a fugitive emissions survey or periodic screening event"
                        disabled={props.readOnly}
                    />
                    <CheckboxField
                        fieldName="investigationSteps"
                        nestedIndex={3}
                        label="(iv) Review continuous monitoring data"
                        disabled={props.readOnly}
                    />
                    <CheckboxField
                        fieldName="investigationSteps"
                        nestedIndex={4}
                        label="(v) Screen the entire oil and natural gas facility with OGI, Method 21 or an alternative test method"
                        disabled={props.readOnly}
                    />
                    <hr className="my-4" />
                    <div className="w-full grid grid-cols-[3fr_2fr] gap-x-12 gap-y-4">
                        <p>
                            Alternative Test Method(s) approved per
                            §60.5398b(d), such as MATM-XXXX
                        </p>
                        <TextField
                            fieldName="alternateTestMethods"
                            disabled={props.readOnly}
                        />
                        <p>What day was this investigation?*</p>
                        <DateField
                            fieldName="investigationDate"
                            disabled={props.readOnly}
                        />
                        <p>What time?</p>
                        <TimeField
                            fieldName="investigationTime"
                            disabled={props.readOnly}
                        />
                        <p>What time zone?</p>
                        <TimezonePicker
                            fieldName="investigationTimezone"
                            disabled={props.readOnly}
                        />
                    </div>
                    {props.epaNotification.notificationReason ===
                        "EPA_NOTIFICATION" && <CertifyNotice />}
                </FormSection>
                <FormSection
                    title="Evaluation of source"
                    description="Provide details about the source and applicable regulations."
                >
                    <div className="w-full grid grid-cols-[3fr_2fr] gap-x-12 gap-y-4">
                        <p>What was the source of the emission?*</p>
                        <TextField
                            fieldName="sourceOfEmission"
                            disabled={props.readOnly}
                        />
                        <p>
                            Is there source subject to regulation under NSPS
                            OOOO, NSPS OOOOa, NSPS OOOOb, and/ore a State,
                            Tribal or Federal plan implementing OOOOc?*
                        </p>
                        <div>
                            <CheckboxField
                                fieldName="subjectToRegulation"
                                nestedIndex={0}
                                label="OOOO"
                                disabled={props.readOnly}
                            />
                            <CheckboxField
                                fieldName="subjectToRegulation"
                                nestedIndex={1}
                                label="OOOOa"
                                disabled={props.readOnly}
                            />
                            <CheckboxField
                                fieldName="subjectToRegulation"
                                nestedIndex={2}
                                label="OOOOb"
                                disabled={props.readOnly}
                            />
                            <CheckboxField
                                fieldName="subjectToRegulation"
                                nestedIndex={3}
                                label="OOOOc"
                                disabled={props.readOnly}
                            />
                            <CheckboxField
                                fieldName="subjectToRegulation"
                                nestedIndex={4}
                                label="None"
                                disabled={props.readOnly}
                            />
                        </div>
                        <p>
                            Identify by citation the applicable regulation(s)
                            within each applicable subpart
                        </p>
                        <TextArea
                            fieldName="citationOfRegulation"
                            disabled={props.readOnly}
                        />
                    </div>
                </FormSection>
                <FormSection title="Status and response plan" description="">
                    <div className="w-full grid grid-cols-[3fr_2fr] gap-x-12 gap-y-4">
                        <p>Is this emission event ongoing?*</p>
                        <YesNoQuestion
                            fieldName="eventStillOngoing"
                            disabled={props.readOnly}
                        />
                        <div>
                            <p>What day did the emission event end?</p>
                            <p className="text-gray-400">
                                Required if the previous question was answered
                                "No". If the date is unknown, provide an
                                estimate.
                            </p>
                        </div>
                        <DateField
                            fieldName="eventEndDate"
                            disabled={props.readOnly}
                        />
                        <div>
                            <p>Is the above date an estimate?</p>
                            <p className="text-gray-400">
                                Required if the emission is ended.
                            </p>
                        </div>
                        <YesNoQuestion
                            fieldName="isEstimate"
                            disabled={props.readOnly}
                        />
                        <p>What time?</p>
                        <TimeField
                            fieldName="eventEndTime"
                            disabled={props.readOnly}
                        />
                        <p>What time zone?</p>
                        <TimezonePicker
                            fieldName="eventEndTimezone"
                            disabled={props.readOnly}
                        />
                        <div>
                            <p>Targeted end date</p>
                            <p className="text-gray-400">
                                Required if the emission is still ongoing.
                            </p>
                        </div>
                        <DateField
                            fieldName="targetedEndDate"
                            disabled={props.readOnly}
                        />
                        <p>
                            Provide a short narrative of your plan to end the
                            super-emitter event.*
                        </p>
                        <TextArea
                            fieldName="planForEvent"
                            disabled={props.readOnly}
                        />
                        <p>Response narrative attachment(s)</p>
                        <EpaFormUploadField
                            uploadType="STATUS_AND_RESPONSE_PLAN"
                            form={props.epaForm}
                        />
                    </div>
                </FormSection>
                {props.epaNotification.notificationReason ===
                "EPA_NOTIFICATION" ? (
                    <SubmitToCdxNotice />
                ) : (
                    <EpaMatchedInfo />
                )}
                {!props.readOnly && (
                    <>
                        <hr className="my-4" />
                        <div className="h-16 flex items-start gap-4">
                            {props.epaNotification.notificationReason ===
                            "EPA_NOTIFICATION" ? (
                                <>
                                    <PrimaryButton
                                        onClick={(e) => {
                                            if (
                                                confirm(
                                                    "Are you sure you want to proceed? The form will become read-only after this change.",
                                                )
                                            ) {
                                                form.setValue(
                                                    "markAsComplete",
                                                    true,
                                                );
                                                submit(e);
                                            }
                                        }}
                                    >
                                        Save and mark as complete
                                    </PrimaryButton>
                                    <SecondaryButton
                                        onClick={(e) => {
                                            form.setValue(
                                                "markAsSubmitted",
                                                false,
                                            );
                                            submit(e);
                                        }}
                                    >
                                        Save
                                    </SecondaryButton>
                                </>
                            ) : (
                                <PrimaryButton
                                    onClick={(e) => {
                                        form.setValue("markAsSubmitted", false);
                                        submit(e);
                                    }}
                                >
                                    Save
                                </PrimaryButton>
                            )}
                            <SecondaryButton
                                onClick={() => {
                                    if (
                                        form.formState.isDirty &&
                                        !confirm(
                                            "Are you sure you want to close this form? All unsaved changes will be lost.",
                                        )
                                    ) {
                                        return;
                                    }
                                    props.onClose();
                                }}
                            >
                                Cancel
                            </SecondaryButton>
                        </div>
                    </>
                )}
            </form>
        </FormProvider>
    );
};

interface EpaFormModalProps {
    selectedEvent?: string;
    onClose: () => void;
}

export const EpaFormModal = (props: EpaFormModalProps) => {
    const [headerExpanded, setHeaderExpanded] = useState(false);
    const [isDirty, setIsDirty] = useAtom(isDirtyAtom);
    const apiClient = useEpaSepApiClient();
    const notificationQuery = useQuery({
        queryKey: ["epaSepNotification", props.selectedEvent],
        queryFn: async () => {
            return await apiClient.epaSepNotificationsRetrieve({
                id: props.selectedEvent,
            });
        },
        refetchOnWindowFocus: false,
        enabled: !!props.selectedEvent,
        staleTime: 0,
    });

    const formDataQuery = useQuery({
        queryKey: ["epaSepFormData", notificationQuery.data?.epaForm],
        queryFn: async () => {
            return await apiClient.epaSepFormRetrieve({
                id: notificationQuery.data?.epaForm,
            });
        },
        refetchOnWindowFocus: false,
        enabled: !!notificationQuery.data?.epaForm,
        staleTime: 0,
    });

    const readOnly = ["COMPLETE", "SUBMITTED_TO_EPA"].includes(
        notificationQuery.data?.status,
    );

    const handleClose = () => {
        if (!readOnly && isDirty) {
            if (
                confirm(
                    "Are you sure you want to close this form? All unsaved changes will be lost.",
                )
            ) {
                setIsDirty(false);
                props.onClose();
            } else {
                return;
            }
        } else {
            props.onClose();
        }
    };

    return (
        <FullscreenModalBase
            visible={!!props.selectedEvent}
            className="fixed inset-0 bg-ae-gray-100 scroll-smooth overflow-auto w-screen h-screen overscroll-none"
            onClose={handleClose}
        >
            <div className="sticky top-0 z-20 pb-6 mb-6 bg-ae-gray-250 pt-8 border-b border-b-slate-400 group">
                <div className="max-w-[800px] mx-auto transition-all">
                    <div className="flex justify-between">
                        <p className="text-stone-900 text-lg font-semibold mb-4">
                            EPA Super Emitter Program Notification
                        </p>
                        <button
                            type="button"
                            className="w-8 h-8"
                            onClick={handleClose}
                        >
                            <FontAwesomeIcon icon={faX} className="w-4" />
                        </button>
                    </div>
                    <EpaSepNotificationInformation
                        epaNotificationId={props.selectedEvent}
                        expanded={headerExpanded}
                    />
                </div>
                <div className="w-full my-4 -mb-3 flex items-center justify-center">
                    <button
                        className="flex items-center text-sm gap-2 text-ae-blue-550"
                        onClick={() => setHeaderExpanded((v) => !v)}
                    >
                        {headerExpanded ? "Collapse details" : "Expand details"}
                        <FontAwesomeIcon
                            icon={
                                headerExpanded ? faChevronsUp : faChevronsDown
                            }
                            className="w-3"
                        />
                    </button>
                </div>
            </div>
            <div className="relative container mx-auto max-w-[1200px] w-full h-full">
                <div className="max-w-[800px] mx-auto text-neutral-600">
                    {formDataQuery.data ? (
                        <EpaSepReportForm
                            epaForm={formDataQuery.data}
                            epaNotification={notificationQuery.data}
                            onClose={props.onClose}
                            readOnly={readOnly}
                        />
                    ) : (
                        <LoadingIndicator />
                    )}
                </div>
            </div>
        </FullscreenModalBase>
    );
};
