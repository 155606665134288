import {
    faArrowDownToSquare,
    faArrowsRotate,
    faCheckCircle,
    faDownload,
    faInfoCircle,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PrimaryButton, SecondaryButton } from "../../ui/Buttons";
import { ReactNode, useState } from "react";
import { FullscreenModal } from "../../ui/Modals";
import {
    AdminEmissionsRecordsStatsListProviderWithSourceParameterInner,
    DataProvidersListDataSourceEnum,
    EmissionRecordsEmissionRecordStatsRetrieveEmissionGlobalStatusEnum,
    NotificationReasonEnum,
} from "../../apiClient/generated";
import {
    DateRangeFormField,
    MultipleChoicePicker,
    RangeFormField,
} from "../../ui/Inputs";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { array, date, object, string, number } from "yup";
import {
    useAccountsApiClient,
    useAppSelector,
    useDatadownloadsApiClient,
    useEmissionRecordsApiClient,
    useGeoDataApi,
} from "../../hooks";
import { useQuery } from "@tanstack/react-query";
import { GeoFilterFormField } from "../filters/GeoFilterSelect";
import { DataProviderDropdown } from "../filters/ProviderFilter";

export const formSchema = object({
    providerWithSource: array().of(
        object({
            id: string().required(),
            se: array().of(string()).nullable().optional(),
        }).required(),
    ),
    assignedTo: array().of(string()).nullable(),
    detectionDateRangeBefore: date().nullable(),
    detectionDateRangeAfter: date().nullable(),
    createdAtBefore: date().nullable(),
    createdAtAfter: date().nullable(),
    locationWithin: string().nullable(),
    locationPreset: array().of(number()),
    emissionGlobalStatus: array().of(string()).nullable(),
    detectedRateMin: number().min(0).nullable(),
    detectedRateMax: number().min(0).nullable(),
    concentrationMin: number().min(0).nullable(),
    concentrationMax: number().min(0).nullable(),
    epaSepNotificationReason: array().of(string()).nullable(),
});

const InputGroup = ({
    label,
    children,
    loading,
}: {
    label: string;
    children: ReactNode;
    loading?: boolean;
}) => (
    <div className="grid grid-cols-8 items-center">
        <label htmlFor="name" className="col-span-2 mr-4 text-gray-700">
            {label}
        </label>
        <div className="col-span-6">
            {loading ? (
                <FontAwesomeIcon
                    icon={faArrowsRotate}
                    className="w-4 animate-spin"
                />
            ) : (
                <>{children}</>
            )}
        </div>
    </div>
);

interface DataExportFormProps {
    dataSource: DataProvidersListDataSourceEnum;
    onFinish: (exportId?: string) => void;
    onCancel: () => void;
}

/**
 * Data export form
 *
 * Handles all the filter fields available for CSV data
 * export generation.
 *
 * // FIXME: Implement error states on form.
 * // FIXME: Add validation to choice fields (minor thing).
 */
const DataExportForm = (props: DataExportFormProps) => {
    const isEpa = props.dataSource === "EPA";
    // Form setup
    const form = useForm({
        resolver: yupResolver(formSchema),
    });

    // Get user list
    const accountsApiClient = useAccountsApiClient();
    const usersQuery = useQuery({
        queryKey: ["usersForFilter"],
        queryFn: async () => {
            return await accountsApiClient.accountsUsersList();
        },
        refetchOnWindowFocus: false,
        staleTime: 0,
    });

    // Get list of presets
    const geoDataApiClient = useGeoDataApi();
    const geoDataFiltersQuery = useQuery({
        queryKey: ["geoDataFilterItems"],
        queryFn: async () => {
            const response = await geoDataApiClient.geodataItemsList({
                pageSize: 500,
            });
            return response.results;
        },
        refetchOnWindowFocus: false,
        staleTime: 0,
    });

    // When submitting form
    const apiClient = useEmissionRecordsApiClient();
    const onSubmit = async (data) => {
        try {
            const response =
                await apiClient.emissionRecordsGenerateCsvReportCreate({
                    ...data,
                    dataSource: props.dataSource,
                    assignedTo:
                        data.assignedTo?.length === 0
                            ? undefined
                            : data.assignedTo,
                    locationPreset:
                        data.locationPreset?.length === 0
                            ? undefined
                            : data.locationPreset,
                    providerWithSource:
                        data.providerWithSource?.length === 0
                            ? undefined
                            : JSON.stringify(data.providerWithSource),
                    emissionGlobalStatus:
                        data.emissionGlobalStatus?.length === 0
                            ? undefined
                            : data.emissionGlobalStatus,
                    detectedRateMin: data.detectedRateMin
                        ? data.detectedRateMin * 1000
                        : undefined,
                    detectedRateMax: data.detectedRateMax
                        ? data.detectedRateMax * 1000
                        : undefined,
                    epaSepNotificationReason:
                        data.epaSepNotificationReason?.length === 0
                            ? undefined
                            : data.epaSepNotificationReason,
                });
            if (response.status === "no_items") {
                alert("No emission record data found for selected filters.");
            } else if (response.status === "already_in_progress") {
                alert(
                    "You already requested a data export for the given filters.",
                );
            } else {
                props.onFinish(response.dataDownloadId);
            }
        } catch (e) {
            let errorMsg = "Error while generating report. ";
            if (data.locationWithin) {
                errorMsg +=
                    "Reduce the complexity/size of the attached file and try again.";
            }
            alert(errorMsg);
        }
    };

    return (
        <div>
            <p className="text-neutral-800 text-base">
                Choose and apply filters to customize your file by narrowing
                down the criteria to your selections.
            </p>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <div className="grid gap-6 text-sm my-8 max-w-[800px]">
                    {!isEpa && (
                        <>
                            {" "}
                            <InputGroup label="Provider">
                                <Controller
                                    name="providerWithSource"
                                    control={form.control}
                                    render={({ field }) => (
                                        <DataProviderDropdown
                                            filterValue={
                                                field.value as unknown as AdminEmissionsRecordsStatsListProviderWithSourceParameterInner[]
                                            }
                                            setFilterValue={field.onChange}
                                        />
                                    )}
                                />
                            </InputGroup>
                            <InputGroup
                                label="Assignee"
                                loading={!usersQuery.data}
                            >
                                <Controller
                                    name="assignedTo"
                                    control={form.control}
                                    render={({ field }) => (
                                        <MultipleChoicePicker
                                            value={field.value}
                                            onChange={field.onChange}
                                            options={usersQuery.data.map(
                                                (i) => ({
                                                    id: i.id,
                                                    label: `${i.firstName} ${i.lastName}`,
                                                }),
                                            )}
                                            placeholder="All assignees"
                                        />
                                    )}
                                />
                            </InputGroup>
                        </>
                    )}
                    <InputGroup label="Detection date">
                        <DateRangeFormField
                            control={form.control}
                            afterFieldName="detectionDateRangeAfter"
                            beforeFieldName="detectionDateRangeBefore"
                            showPresets
                        />
                    </InputGroup>
                    <InputGroup label="Import date">
                        <DateRangeFormField
                            control={form.control}
                            afterFieldName="createdAtAfter"
                            beforeFieldName="createdAtBefore"
                            showPresets
                        />
                    </InputGroup>
                    <InputGroup
                        label="Location"
                        loading={!geoDataFiltersQuery.data}
                    >
                        <GeoFilterFormField
                            control={form.control}
                            locationPresetName="locationPreset"
                            locationWhitinName="locationWithin"
                        />
                    </InputGroup>
                    {!isEpa && (
                        <InputGroup label="Status">
                            <Controller
                                name="emissionGlobalStatus"
                                control={form.control}
                                render={({ field }) => (
                                    <MultipleChoicePicker
                                        value={field.value}
                                        onChange={field.onChange}
                                        options={Object.values(
                                            EmissionRecordsEmissionRecordStatsRetrieveEmissionGlobalStatusEnum,
                                        ).map((i) => ({
                                            id: i,
                                            label: i
                                                .replaceAll("_", " ")
                                                .toLowerCase(),
                                        }))}
                                        placeholder="All status"
                                    />
                                )}
                            />
                        </InputGroup>
                    )}
                    <InputGroup label="Detection rate">
                        <RangeFormField
                            control={form.control}
                            minFieldName="detectedRateMin"
                            maxFieldName="detectedRateMax"
                            units="kg/h"
                        />
                    </InputGroup>
                    {!isEpa && (
                        <InputGroup label="Concentration rate">
                            <RangeFormField
                                control={form.control}
                                minFieldName="concentrationMin"
                                maxFieldName="concentrationMax"
                                units="ppm*m"
                            />
                        </InputGroup>
                    )}
                    {isEpa && (
                        <InputGroup label="EPA Notification types">
                            <Controller
                                name="epaSepNotificationReason"
                                control={form.control}
                                render={({ field }) => (
                                    <MultipleChoicePicker
                                        value={field.value}
                                        onChange={field.onChange}
                                        options={[
                                            {
                                                id: NotificationReasonEnum.EpaNotification,
                                                label: "EPA notifications",
                                            },
                                            {
                                                id: NotificationReasonEnum.MatchedInfrastructure,
                                                label: "Near my infrastructure (non-official)",
                                            },
                                        ]}
                                        placeholder="All EPA notifications"
                                    />
                                )}
                            />
                        </InputGroup>
                    )}
                </div>
                <hr className="my-4" />
                <div className="flex gap-4">
                    <PrimaryButton
                        disabled={
                            form.formState.isLoading ||
                            form.formState.isSubmitting
                        }
                    >
                        Download CSV
                    </PrimaryButton>
                    <SecondaryButton
                        onClick={(e) => {
                            props.onCancel();
                            e.preventDefault();
                        }}
                        disabled={
                            form.formState.isLoading ||
                            form.formState.isSubmitting
                        }
                    >
                        Close
                    </SecondaryButton>
                </div>
            </form>
        </div>
    );
};

interface DataExportStatusProps {
    exportId: string;
    onClose: () => void;
}

export const DataExportStatus = (props: DataExportStatusProps) => {
    const apiClient = useDatadownloadsApiClient();
    const exportStatusQuery = useQuery({
        queryKey: ["exportStatus", props.exportId],
        queryFn: async () => {
            const response = await apiClient.datadownloadsDataPackageRetrieve({
                id: props.exportId,
            });
            return response;
        },
        refetchInterval: (data) => {
            return !data || data?.dataDownloadStatus !== "READY" ? 4000 : false;
        },
    });

    const downloadArchive = async (dataPackageId: string) => {
        const response =
            await apiClient.datadownloadsDataPackageDownloadUrlRetrieve({
                id: dataPackageId,
            });
        window.open(response.downloadUrl);
    };

    if (
        exportStatusQuery.data &&
        exportStatusQuery.data.dataDownloadStatus === "READY"
    ) {
        return (
            <div className="w-full h-full flex flex-col items-center mt-16 gap-6">
                <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="h-20 text-ae-blue-550"
                />
                <p className="font-bold text-2xl">Your data export is ready!</p>
                <p className="max-w-96 text-center">
                    Click the button below to download your file.
                </p>
                <PrimaryButton onClick={() => downloadArchive(props.exportId)}>
                    <FontAwesomeIcon icon={faArrowDownToSquare} />
                    Download
                </PrimaryButton>
            </div>
        );
    }

    return (
        <div className="w-full h-full flex flex-col items-center mt-16 gap-6">
            <FontAwesomeIcon
                icon={faInfoCircle}
                className="h-20 text-ae-blue-550"
            />
            <p className="font-bold text-2xl">
                Your export is being processed!
            </p>
            <p className="max-w-96 text-center">
                Your file is being processed and may take some time to prepare.
                If you navigate away we'll notify you via email when the file is
                ready.
            </p>
            <div>
                <FontAwesomeIcon
                    icon={faArrowsRotate}
                    className="animate-spin h-8"
                />
            </div>
            <p>Thank you for your patience!</p>

            <SecondaryButton onClick={props.onClose}>Close</SecondaryButton>
        </div>
    );
};

interface DataExportProps {
    dataSource: DataProvidersListDataSourceEnum;
}

export const DataExport = (props: DataExportProps) => {
    const permissions = useAppSelector((s) => s.auth.permissions);
    const [modalOpen, setModalOpen] = useState(false);
    const [exportId, setExportId] = useState<string>(undefined);

    const closeModal = () => {
        setExportId(undefined);
        setModalOpen(false);
    };

    if (!permissions.emissionExport) {
        return <div className="h-6" />;
    }

    return (
        <div className="mt-2 flex justify-end">
            <SecondaryButton onClick={() => setModalOpen(true)} variant="sm">
                <FontAwesomeIcon icon={faDownload} className="" />
                Export as CSV
            </SecondaryButton>
            <FullscreenModal
                visible={modalOpen}
                onClose={closeModal}
                title="Export Emissions Data as CSV"
            >
                {exportId ? (
                    <DataExportStatus
                        exportId={exportId}
                        onClose={closeModal}
                    />
                ) : (
                    <DataExportForm
                        dataSource={props.dataSource}
                        onFinish={(id) => setExportId(id)}
                        onCancel={closeModal}
                    />
                )}
            </FullscreenModal>
        </div>
    );
};
