/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EpaNotificationRequest } from './EpaNotificationRequest';
import {
    EpaNotificationRequestFromJSON,
    EpaNotificationRequestFromJSONTyped,
    EpaNotificationRequestToJSON,
} from './EpaNotificationRequest';
import type { EpaOperatorNotificationStatusEnum } from './EpaOperatorNotificationStatusEnum';
import {
    EpaOperatorNotificationStatusEnumFromJSON,
    EpaOperatorNotificationStatusEnumFromJSONTyped,
    EpaOperatorNotificationStatusEnumToJSON,
} from './EpaOperatorNotificationStatusEnum';
import type { SimpleDataPointRequest } from './SimpleDataPointRequest';
import {
    SimpleDataPointRequestFromJSON,
    SimpleDataPointRequestFromJSONTyped,
    SimpleDataPointRequestToJSON,
} from './SimpleDataPointRequest';
import type { SimpleInfrastructureRequest } from './SimpleInfrastructureRequest';
import {
    SimpleInfrastructureRequestFromJSON,
    SimpleInfrastructureRequestFromJSONTyped,
    SimpleInfrastructureRequestToJSON,
} from './SimpleInfrastructureRequest';

/**
 * Serializer for the EpaOperatorNotification model.
 * @export
 * @interface PatchedEpaOperatorNotificationRequest
 */
export interface PatchedEpaOperatorNotificationRequest {
    /**
     * 
     * @type {EpaNotificationRequest}
     * @memberof PatchedEpaOperatorNotificationRequest
     */
    notification?: EpaNotificationRequest;
    /**
     * 
     * @type {SimpleInfrastructureRequest}
     * @memberof PatchedEpaOperatorNotificationRequest
     */
    nearestSite?: SimpleInfrastructureRequest;
    /**
     * 
     * @type {Array<SimpleInfrastructureRequest>}
     * @memberof PatchedEpaOperatorNotificationRequest
     */
    otherNearSites?: Array<SimpleInfrastructureRequest>;
    /**
     * 
     * @type {SimpleDataPointRequest}
     * @memberof PatchedEpaOperatorNotificationRequest
     */
    dataPoint?: SimpleDataPointRequest;
    /**
     * 
     * @type {string}
     * @memberof PatchedEpaOperatorNotificationRequest
     */
    epaEmissionRecord?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchedEpaOperatorNotificationRequest
     */
    assignee?: number;
    /**
     * 
     * @type {EpaOperatorNotificationStatusEnum}
     * @memberof PatchedEpaOperatorNotificationRequest
     */
    status?: EpaOperatorNotificationStatusEnum;
}

/**
 * Check if a given object implements the PatchedEpaOperatorNotificationRequest interface.
 */
export function instanceOfPatchedEpaOperatorNotificationRequest(value: object): boolean {
    return true;
}

export function PatchedEpaOperatorNotificationRequestFromJSON(json: any): PatchedEpaOperatorNotificationRequest {
    return PatchedEpaOperatorNotificationRequestFromJSONTyped(json, false);
}

export function PatchedEpaOperatorNotificationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedEpaOperatorNotificationRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'notification': json['notification'] == null ? undefined : EpaNotificationRequestFromJSON(json['notification']),
        'nearestSite': json['nearest_site'] == null ? undefined : SimpleInfrastructureRequestFromJSON(json['nearest_site']),
        'otherNearSites': json['other_near_sites'] == null ? undefined : ((json['other_near_sites'] as Array<any>).map(SimpleInfrastructureRequestFromJSON)),
        'dataPoint': json['data_point'] == null ? undefined : SimpleDataPointRequestFromJSON(json['data_point']),
        'epaEmissionRecord': json['epa_emission_record'] == null ? undefined : json['epa_emission_record'],
        'assignee': json['assignee'] == null ? undefined : json['assignee'],
        'status': json['status'] == null ? undefined : EpaOperatorNotificationStatusEnumFromJSON(json['status']),
    };
}

export function PatchedEpaOperatorNotificationRequestToJSON(value?: PatchedEpaOperatorNotificationRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'notification': EpaNotificationRequestToJSON(value['notification']),
        'nearest_site': SimpleInfrastructureRequestToJSON(value['nearestSite']),
        'other_near_sites': value['otherNearSites'] == null ? undefined : ((value['otherNearSites'] as Array<any>).map(SimpleInfrastructureRequestToJSON)),
        'data_point': SimpleDataPointRequestToJSON(value['dataPoint']),
        'epa_emission_record': value['epaEmissionRecord'],
        'assignee': value['assignee'],
        'status': EpaOperatorNotificationStatusEnumToJSON(value['status']),
    };
}

