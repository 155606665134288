import { useMemo } from "react";
import { FullscreenModal } from "../../ui/Modals";
import { MultipleEmissionRecordDisplay } from "../Emissions/EmissionRecordDisplay";
import { EmissionRecordPreviewMap } from "../Emissions/EmissionRecordPreviewMap";
import { Controller, FormProvider, useFormContext } from "react-hook-form";
import {
    useAccountsApiClient,
    useAppSelector,
    useEmissionEventsApiClient,
    useEmissionRecordsApiClient,
    useInfrastructureApiClient,
    useRootCausesApiClient,
} from "../../hooks";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
    EmissionRecordView,
    SimpleInfrastructure,
} from "../../apiClient/generated";
import { UserIcon } from "@heroicons/react/24/outline";
import { PrimaryButton, SecondaryButton } from "../../ui/Buttons";
import { DateTime } from "luxon";
import { InputQuestionUi } from "../../ui/Forms";
import { FilteringDropdown } from "../../ui/Inputs";
import { useEventForm } from "./eventForm";
import { LoadingIndicator } from "../MapV2/ui/LoadingIndicator";
import { AlignedDataDisplay } from "../../ui/GenericFields";
import { Link } from "react-router-dom";
import { ROUTES } from "../../routes";
import { InfrastructureDisplay } from "./ui/InfrastructureDisplay";

interface FormFieldProps {
    fieldName: string;
    disabled?: boolean;
}

const YesNoQuestion = ({ fieldName, disabled }: FormFieldProps) => {
    const form = useFormContext();
    return (
        <Controller
            name={fieldName}
            control={form.control}
            render={({ field }) => (
                <div className="ml-1">
                    <label
                        className={`flex items-center gap-2 mb-2 ${
                            disabled && "text-gray-700 italic"
                        }`}
                    >
                        <input
                            type="radio"
                            disabled={disabled}
                            value="yes"
                            checked={field.value === true}
                            onChange={() => field.onChange(true)}
                            className="disabled:text-gray-600"
                        />
                        Yes
                    </label>
                    <label
                        className={`flex items-center gap-2 mb-2 ${
                            disabled && "text-gray-700 italic"
                        }`}
                    >
                        <input
                            type="radio"
                            disabled={disabled}
                            value="no"
                            checked={field.value === false}
                            onChange={() => field.onChange(false)}
                            className="disabled:text-gray-600"
                        />
                        No
                    </label>
                </div>
            )}
        />
    );
};

interface RelatedEmissionDropdownProps {
    relatedEmissions: EmissionRecordView[];
}

export const RelatedEmissionDropdown = (
    props: RelatedEmissionDropdownProps,
) => {
    const form = useFormContext();
    const { relatedEmissions } = props;

    return (
        <Controller
            name="similarEmission"
            control={form.control}
            render={({ field }) => (
                <div>
                    {relatedEmissions.map((r, idx) => {
                        const timeString = DateTime.fromJSDate(
                            r.dataPoint.detectionTimestamp,
                        )
                            .setZone("utc")
                            .toLocaleString(DateTime.DATETIME_SHORT);
                        const event = r.events[0];
                        const emissionString =
                            r.dataPoint.detectedRate !== 0
                                ? `${(r.dataPoint.detectedRate / 1000).toFixed(
                                      2,
                                  )} kg/h`
                                : `${r.dataPoint.concentration} ppm*m`;
                        const data = [
                            {
                                label: "Provider Name:",
                                value: (
                                    <>
                                        {r.providerName}
                                        <Link
                                            className="ml-1 text-xs underline"
                                            to={`${ROUTES.LICENSING}?section_id=${r.providerName}`}
                                            target="_blank"
                                        >
                                            (License)
                                        </Link>
                                    </>
                                ),
                            },
                            {
                                label: "Secondary data source:",
                                value: r.dataPoint?.secondaryDataSource || "-",
                            },
                            r.dataPoint?.data?.quantification_institution
                                ? {
                                      label: "Quantifying institution:",
                                      value: r.dataPoint?.data
                                          ?.quantification_institution,
                                  }
                                : undefined,
                            {
                                label: "Detection Date (UTC):",
                                value: timeString,
                            },
                            {
                                label: "Detected rate/concentration:",
                                value: emissionString,
                            },
                            {
                                label: "Status",
                                value: event.eventStatus
                                    .replaceAll("_", " ")
                                    .toLowerCase()
                                    .replace(/^\w/, (c) => c.toUpperCase()),
                            },
                        ].filter((x) => x) as { label: string; value: any }[];

                        if (event.rootCause) {
                            data.push({
                                label: "Root cause",
                                value: event.rootCause.name,
                            });
                        }

                        return (
                            <label className="flex items-center gap-2 mb-3 ml-1 rounded border p-2">
                                <input
                                    type="radio"
                                    value="no"
                                    checked={field.value === event.id}
                                    onChange={() => field.onChange(event.id)}
                                />
                                <div>
                                    <p className="font-bold ml-1">
                                        Existing Event {idx + 1}
                                    </p>
                                    <AlignedDataDisplay data={data} />
                                </div>
                            </label>
                        );
                    })}
                    <label className="flex items-center gap-2 mb-2 ml-3">
                        <input
                            type="radio"
                            value="no"
                            checked={field.value === null}
                            onChange={() => field.onChange(null)}
                        />
                        <div>No, create new event.</div>
                    </label>
                </div>
            )}
        />
    );
};

interface UserDropdownProps {
    value: number;
    currentUserId?: number;
    company?: number;
    onChange: (value: number) => void;
}

export const UserDropdown = (props: UserDropdownProps) => {
    const apiClient = useAccountsApiClient();

    const peopleQuery = useQuery({
        queryKey: ["companyUserList"],
        queryFn: async () => {
            return await apiClient.accountsUsersList({
                companymembershipCompany: props.company,
            });
        },
    });

    const options = useMemo(() => {
        if (!peopleQuery.data) {
            return [];
        }
        return peopleQuery.data.map((p) => {
            return {
                id: p.id,
                displayName: `${p.firstName} ${p.lastName} (${p.email})`,
                searchString:
                    `${p.firstName} ${p.lastName} ${p.email}`.toLowerCase(),
            };
        });
    }, [peopleQuery.data]);

    const extraActions = [];
    if (
        props.value !== props.currentUserId &&
        peopleQuery.data?.find((u) => u.id === props.currentUserId)
    ) {
        extraActions.push({
            icon: <UserIcon className="h-4 w-4 text-gray-400" />,
            title: "Set myself as responsible",
            action: () => {
                if (peopleQuery.data.length > 0) {
                    props.onChange(props.currentUserId);
                }
            },
        });
    }

    return (
        <FilteringDropdown
            value={props.value}
            onChange={props.onChange}
            options={options}
            extraActions={extraActions}
        />
    );
};

interface RootCauseDropdownProps {
    value: number;
    onChange: (value: number) => void;
}

export const RootCauseDropdown = (props: RootCauseDropdownProps) => {
    const apiClient = useRootCausesApiClient();

    const rootCausesQuery = useQuery({
        queryKey: ["rootCauseList"],
        queryFn: async () => {
            return await apiClient.rootcausesList();
        },
    });

    const options = useMemo(() => {
        if (!rootCausesQuery.data) {
            return [];
        }
        return rootCausesQuery.data.map((r) => {
            return {
                id: r.id,
                displayName: r.name,
                searchString: r.name.toLowerCase(),
            };
        });
    }, [rootCausesQuery.data]);

    return (
        <FilteringDropdown
            value={props.value}
            onChange={props.onChange}
            options={options}
            extraActions={[]}
        />
    );
};

interface InfrastructureDropdownProps {
    value?: string | null;
    infrastructure: SimpleInfrastructure[];
    onChange: (value?: string) => void;
    emptyValue: string;
}

export const InfrastructureDropdown = (props: InfrastructureDropdownProps) => {
    const options = useMemo(() => {
        if (!props.infrastructure) {
            return [];
        }
        return props.infrastructure
            .map((i) => {
                return {
                    id: i.id,
                    displayName: `${i.name || i.siteName} (${
                        i.location &&
                        i.location.coordinates
                            .toReversed()
                            .map((c) => c.toFixed(4))
                            .join(", ")
                    })`,
                    searchString: `${i.siteName} ${i.name}`.toLowerCase(),
                };
            })
            .toSorted((a, b) =>
                a.displayName.localeCompare(b.displayName, undefined, {
                    numeric: true,
                }),
            );
    }, [props.infrastructure]);

    return (
        <FilteringDropdown
            value={props.value}
            onChange={props.onChange}
            options={options}
            extraActions={[]}
            emptyValue={props.emptyValue}
        />
    );
};

interface EventFormProps {
    emissionRecord: EmissionRecordView;
}

const EventView = (props: EventFormProps) => {
    const accepted = props.emissionRecord.emissionStatus === "ACCEPTED";
    const event =
        props.emissionRecord.events.length > 0 &&
        props.emissionRecord.events[0];

    return (
        <div className="text-sm">
            <InputQuestionUi label="Accepted for further investigation?">
                <span className="font-bold">{accepted ? "Yes" : "No"}</span>
            </InputQuestionUi>

            {accepted && (
                <>
                    <hr className="my-4" />

                    <InputQuestionUi label="Assignee:">
                        <span className="font-bold">
                            {event?.responsibleName || "-"}
                        </span>
                    </InputQuestionUi>

                    <InputQuestionUi label="Infrastructure:">
                        <span className="font-bold">
                            {!props.emissionRecord.infrastructure &&
                                "Event not associated with infrastructure."}
                            {props.emissionRecord.infrastructureData?.name ||
                                props.emissionRecord.infrastructureData
                                    ?.siteName}
                        </span>
                    </InputQuestionUi>

                    {/* Root cause */}
                    <hr className="my-4" />

                    <InputQuestionUi label="Root cause:">
                        <span className="font-bold">
                            {event.rootCause?.name}
                        </span>
                    </InputQuestionUi>

                    <InputQuestionUi label="Start date time of event:">
                        <span className="font-bold">
                            {event.startDate
                                ? DateTime.fromJSDate(event.startDate)
                                      .setZone("utc")
                                      .toLocaleString(
                                          DateTime.DATETIME_MED_WITH_SECONDS,
                                      )
                                : "-"}
                        </span>
                    </InputQuestionUi>

                    <InputQuestionUi label="End date time of event:">
                        <span className="font-bold">
                            {event.endDate
                                ? DateTime.fromJSDate(event.endDate)
                                      .setZone("utc")
                                      .toLocaleString(
                                          DateTime.DATETIME_MED_WITH_SECONDS,
                                      )
                                : "-"}
                        </span>
                    </InputQuestionUi>

                    <InputQuestionUi label="How event duration was constrained:">
                        <span className="font-bold">{event.notes}</span>
                    </InputQuestionUi>
                </>
            )}
            {!accepted && (
                <InputQuestionUi label="Rejection reason">
                    <span className="font-bold">
                        {props.emissionRecord.rejectReason || "-"}
                    </span>
                </InputQuestionUi>
            )}
        </div>
    );
};

interface EventManagementProps {
    emissionRecord: EmissionRecordView;
    onClose: () => void;
}

export const EventManagementEdit = (props: EventManagementProps) => {
    const infrastructureApiClient = useInfrastructureApiClient();
    const emissionsApiClient = useEmissionRecordsApiClient();
    const currentUserId = useAppSelector((state) => state.auth.userId);
    const { form, submit } = useEventForm(props.emissionRecord, () =>
        props.onClose(),
    );

    const allowAccept =
        props.emissionRecord.emissionStatus === "MATCHED" ||
        props.emissionRecord.emissionStatus === "NEAR_MATCH";

    const accept = form.watch("accept");
    const responsible = form.watch("responsible");
    const similarEmission = form.watch("similarEmission");

    // If there's any related event load all related emissions
    const relatedEventId =
        props.emissionRecord.events.length > 0 &&
        props.emissionRecord.events[0].id;
    const emissionQuery = useQuery({
        queryKey: ["emissionsRelatedToEvent", relatedEventId],
        queryFn: async () => {
            const response = await emissionsApiClient.emissionRecordsList({
                relatedEvent: relatedEventId,
            });
            return response.results;
        },
        enabled: !!relatedEventId,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
    });

    // Look for similar events
    // Only retrieve query if emission is not accepted yet.
    const similarEmissionsQuery = useQuery({
        queryKey: ["similarEmissions", props.emissionRecord.id],
        queryFn: async () => {
            return await emissionsApiClient.emissionRecordsGetNearbyEventsList({
                id: props.emissionRecord.id,
            });
        },
        enabled: !accept,
        refetchOnWindowFocus: false,
    });

    // Handle infrastructure detail field
    const infrastructure = form.watch("infrastructure");
    const infrastructureDetailQuery = useQuery({
        queryKey: ["infrastructureChildren", infrastructure],
        queryFn: async () => {
            return await infrastructureApiClient.infrastructureListList({
                parent: [infrastructure],
                pageSize: 30,
            });
        },
        enabled: !!infrastructure,
        refetchOnWindowFocus: false,
    });
    const infrastructureDetailOptions = useMemo(() => {
        if (
            !infrastructureDetailQuery.data ||
            infrastructureDetailQuery.data.results.length == 0
        ) {
            return [];
        }
        return infrastructureDetailQuery.data.results.filter(
            (i) => i.id !== infrastructure,
        );
    }, [infrastructureDetailQuery.data, infrastructure]);

    return (
        <FormProvider {...form}>
            <form onSubmit={submit}>
                <div className="flex mt-10 gap-6 text-sm h-[600px]">
                    {/* Data and questions */}
                    <div className="w-2/5 h-[600px] overflow-y-scroll">
                        <MultipleEmissionRecordDisplay
                            emissionRecords={
                                relatedEventId
                                    ? emissionQuery.data
                                    : [props.emissionRecord]
                            }
                            showMatches
                        />
                        <hr className="my-4" />
                        <div>
                            {allowAccept &&
                                similarEmissionsQuery.data &&
                                similarEmissionsQuery.data.length > 0 && (
                                    <InputQuestionUi label="Similar emissions where found for this site, do you want to link them to already created events?">
                                        <RelatedEmissionDropdown
                                            relatedEmissions={
                                                similarEmissionsQuery.data
                                            }
                                        />
                                    </InputQuestionUi>
                                )}

                            {(similarEmission === null ||
                                (similarEmissionsQuery.data &&
                                    similarEmissionsQuery.data.length ==
                                        0)) && (
                                <>
                                    <InputQuestionUi label="Accept this event for further investigation?">
                                        <YesNoQuestion
                                            fieldName="accept"
                                            disabled={!allowAccept}
                                        />
                                    </InputQuestionUi>
                                    {form.formState.errors.accept && (
                                        <span className="ml-1 text-red-500">
                                            {
                                                form.formState.errors.accept
                                                    .message as string
                                            }
                                        </span>
                                    )}

                                    {accept && (
                                        <>
                                            <hr className="my-4" />

                                            <InputQuestionUi label="Assign this event to a team member:">
                                                <Controller
                                                    name="responsible"
                                                    control={form.control}
                                                    render={({ field }) => (
                                                        <UserDropdown
                                                            value={field.value}
                                                            currentUserId={
                                                                currentUserId
                                                            }
                                                            company={
                                                                props
                                                                    .emissionRecord
                                                                    ?.owner
                                                            }
                                                            onChange={(value) =>
                                                                field.onChange(
                                                                    value,
                                                                )
                                                            }
                                                        />
                                                    )}
                                                />
                                                {form.formState.errors
                                                    .responsible && (
                                                    <span className="ml-1 text-red-500">
                                                        {
                                                            form.formState
                                                                .errors
                                                                .responsible
                                                                .message as string
                                                        }
                                                    </span>
                                                )}
                                                {responsible &&
                                                    responsible !==
                                                        currentUserId && (
                                                        <div className="mt-2">
                                                            <label className="flex items-center gap-2 ml-1">
                                                                <input
                                                                    type="checkbox"
                                                                    className="text-ae-blue-500 rounded"
                                                                    {...form.register(
                                                                        "notifyResponsible",
                                                                    )}
                                                                />
                                                                Notify team
                                                                member via email
                                                            </label>
                                                        </div>
                                                    )}
                                            </InputQuestionUi>
                                            <InputQuestionUi label="Associate this event with a site:">
                                                <Controller
                                                    name="infrastructure"
                                                    control={form.control}
                                                    render={({ field }) => (
                                                        <InfrastructureDropdown
                                                            value={field.value}
                                                            onChange={(value) =>
                                                                field.onChange(
                                                                    value,
                                                                )
                                                            }
                                                            infrastructure={
                                                                props
                                                                    .emissionRecord
                                                                    ?.matches
                                                            }
                                                            emptyValue="Event not associated with my infrastructure"
                                                        />
                                                    )}
                                                />
                                            </InputQuestionUi>
                                            {infrastructure && (
                                                <InfrastructureDisplay
                                                    infrastructureId={
                                                        infrastructure
                                                    }
                                                />
                                            )}

                                            {infrastructure &&
                                                infrastructureDetailOptions.length >
                                                    0 && (
                                                    <InputQuestionUi
                                                        label="Associate with equipment at this site:"
                                                        optional
                                                    >
                                                        <Controller
                                                            name="infrastructureDetail"
                                                            control={
                                                                form.control
                                                            }
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <InfrastructureDropdown
                                                                    value={
                                                                        field.value
                                                                    }
                                                                    onChange={(
                                                                        value,
                                                                    ) =>
                                                                        field.onChange(
                                                                            value,
                                                                        )
                                                                    }
                                                                    infrastructure={
                                                                        infrastructureDetailOptions
                                                                    }
                                                                    emptyValue="Don't associate with equipment now"
                                                                />
                                                            )}
                                                        />
                                                    </InputQuestionUi>
                                                )}

                                            {infrastructure && (
                                                <>
                                                    <hr className="my-4" />

                                                    {/* Root cause */}
                                                    <p className="font-bold">
                                                        Investigation results
                                                    </p>

                                                    <InputQuestionUi label="Root cause:">
                                                        <Controller
                                                            name="rootCause"
                                                            control={
                                                                form.control
                                                            }
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <RootCauseDropdown
                                                                    value={
                                                                        field.value
                                                                    }
                                                                    onChange={(
                                                                        value,
                                                                    ) =>
                                                                        field.onChange(
                                                                            value,
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                        {form.formState.errors
                                                            .rootCause && (
                                                            <span className="ml-1 text-red-500">
                                                                {
                                                                    form
                                                                        .formState
                                                                        .errors
                                                                        .rootCause
                                                                        .message as string
                                                                }
                                                            </span>
                                                        )}
                                                    </InputQuestionUi>

                                                    <div className="flex gap-4 w-full">
                                                        <InputQuestionUi
                                                            label="Start date of event:"
                                                            optional
                                                        >
                                                            <input
                                                                className="w-full rounded"
                                                                type="date"
                                                                {...form.register(
                                                                    "startDate",
                                                                )}
                                                            />
                                                        </InputQuestionUi>
                                                        <InputQuestionUi
                                                            label="Start time of event (UTC):"
                                                            optional
                                                        >
                                                            <input
                                                                className="w-full rounded"
                                                                type="time"
                                                                {...form.register(
                                                                    "startTime",
                                                                )}
                                                            />
                                                        </InputQuestionUi>
                                                    </div>
                                                    <div className="flex gap-4 w-full">
                                                        <InputQuestionUi
                                                            label="End date of event:"
                                                            optional
                                                        >
                                                            <input
                                                                className="w-full rounded"
                                                                type="date"
                                                                {...form.register(
                                                                    "endDate",
                                                                )}
                                                            />
                                                        </InputQuestionUi>
                                                        <InputQuestionUi
                                                            label="End time of event (UTC):"
                                                            optional
                                                        >
                                                            <input
                                                                className="w-full rounded"
                                                                type="time"
                                                                {...form.register(
                                                                    "endTime",
                                                                )}
                                                            />
                                                        </InputQuestionUi>
                                                    </div>
                                                    <InputQuestionUi
                                                        label="Describe how event duration was constrained:"
                                                        optional
                                                    >
                                                        <textarea
                                                            className="w-full rounded"
                                                            {...form.register(
                                                                "notes",
                                                            )}
                                                        />
                                                    </InputQuestionUi>
                                                </>
                                            )}
                                        </>
                                    )}
                                    {accept === false && (
                                        <>
                                            <hr className="my-4" />
                                            <InputQuestionUi label="Describe why the event is not being accepted:">
                                                <textarea
                                                    className="w-full rounded"
                                                    {...form.register(
                                                        "rejectReason",
                                                    )}
                                                />
                                            </InputQuestionUi>
                                            {form.formState.errors
                                                .rejectReason && (
                                                <span className="ml-1 text-red-500">
                                                    {
                                                        form.formState.errors
                                                            .rejectReason
                                                            .message as string
                                                    }
                                                </span>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>

                    {/* Minimap preview */}
                    <div className="w-3/5">
                        <EmissionRecordPreviewMap
                            emissionRecords={
                                relatedEventId
                                    ? emissionQuery.data || []
                                    : [props.emissionRecord]
                            }
                            infrastructure={props.emissionRecord.matches}
                            onClick={(infra) => {
                                if (
                                    infrastructure &&
                                    infra &&
                                    !["SITE", "PIPELINE"].includes(
                                        infra.infraType,
                                    )
                                ) {
                                    form.setValue(
                                        "infrastructureDetail",
                                        infra.id,
                                    );
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="mt-2">
                    <hr className="my-4" />
                    <div className="flex gap-2">
                        <PrimaryButton
                            onClick={(e) => {
                                form.setValue("markAsComplete", true);
                                submit(e);
                            }}
                            disabled={
                                !(
                                    accept === false ||
                                    (accept === true &&
                                        similarEmission === null &&
                                        responsible === currentUserId)
                                ) || form.formState.isSubmitting
                            }
                        >
                            Save and mark as complete
                        </PrimaryButton>
                        <PrimaryButton
                            onClick={(e) => {
                                form.setValue("markAsComplete", false);
                                submit(e);
                            }}
                            disabled={
                                accept === false || form.formState.isSubmitting
                            }
                        >
                            Save and continue later
                        </PrimaryButton>
                        <SecondaryButton
                            onClick={() => {
                                props.onClose();
                                form.reset();
                            }}
                        >
                            Close
                        </SecondaryButton>
                    </div>
                </div>
            </form>
        </FormProvider>
    );
};

export const EventManagementView = (props: EventManagementProps) => {
    const queryClient = useQueryClient();
    const apiClient = useEmissionRecordsApiClient();
    const eventsApiClient = useEmissionEventsApiClient();

    // If there's any related event load all related emissions
    const relatedEventId =
        props.emissionRecord.events.length > 0 &&
        props.emissionRecord.events[0].id;
    const emissionQuery = useQuery({
        queryKey: ["emissionsRelatedToEvent", relatedEventId],
        queryFn: async () => {
            const response = await apiClient.emissionRecordsList({
                relatedEvent: relatedEventId,
            });
            return response.results;
        },
        enabled: !!relatedEventId,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
    });

    const revertBack = async () => {
        if (props.emissionRecord.events.length > 0) {
            eventsApiClient.emissioneventsUpdate({
                id: props.emissionRecord.events[0].id,
                emissionEventUpdateRequest: {
                    eventStatus: "WORK_IN_PROGRESS",
                },
            });
        } else {
            apiClient.emissionRecordsUpdate({
                id: props.emissionRecord.id,
                emissionRecordUpdateRequest: {
                    emissionStatus: "MATCHED",
                },
            });
        }
        queryClient.invalidateQueries();
    };

    return (
        <>
            <div className="flex mt-10 gap-6 h-[600px]">
                {/* Data and questions */}
                <div className="w-2/5 h-[600px] overflow-y-scroll">
                    <MultipleEmissionRecordDisplay
                        emissionRecords={
                            relatedEventId
                                ? emissionQuery.data
                                : [props.emissionRecord]
                        }
                        showMatches
                    />
                    <hr className="my-4" />
                    <EventView emissionRecord={props.emissionRecord} />
                </div>

                {/* Minimap preview */}
                <div className="w-3/5">
                    <EmissionRecordPreviewMap
                        emissionRecords={
                            relatedEventId
                                ? emissionQuery.data || []
                                : [props.emissionRecord]
                        }
                        infrastructure={props.emissionRecord.matches}
                    />
                </div>
            </div>
            <div className="mt-4">
                <hr className="my-4" />
                <div className="flex gap-2">
                    <SecondaryButton
                        onClick={async () => {
                            await revertBack();
                            props.onClose();
                        }}
                    >
                        Move back to{" "}
                        {props.emissionRecord.events.length > 0
                            ? '"In Progress"'
                            : '"New"'}
                    </SecondaryButton>
                    <PrimaryButton
                        onClick={() => {
                            props.onClose();
                        }}
                    >
                        Close
                    </PrimaryButton>
                </div>
            </div>
        </>
    );
};

interface EventManagementModalProps {
    emissionId: string;
    onClose: () => void;
    title?: string;
}

export const EventManagementModal = (props: EventManagementModalProps) => {
    const apiClient = useEmissionRecordsApiClient();

    // Retrieve emission information
    const emissionQuery = useQuery({
        queryKey: ["emissionRecord", props.emissionId],
        queryFn: async () => {
            return await apiClient.emissionRecordsRetrieve({
                id: props.emissionId,
            });
        },
        enabled: !!props.emissionId,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
    });

    const isEditable =
        emissionQuery.data?.emissionStatus == "NEAR_MATCH" ||
        emissionQuery.data?.emissionStatus == "MATCHED" ||
        (emissionQuery.data?.emissionStatus == "ACCEPTED" &&
            emissionQuery.data?.events.length > 0 &&
            emissionQuery.data.events.some(
                (e) => e.eventStatus !== "COMPLETED",
            ));

    return (
        <FullscreenModal
            title={`Event Information ${props.title && ` - ${props.title}`}`}
            visible={!!props.emissionId}
            onClose={props.onClose}
        >
            {emissionQuery.isLoading ||
                (!emissionQuery.data && <LoadingIndicator />)}
            {!emissionQuery.isLoading && emissionQuery.data && (
                <>
                    {isEditable ? (
                        <EventManagementEdit
                            emissionRecord={emissionQuery.data}
                            onClose={props.onClose}
                        />
                    ) : (
                        <EventManagementView
                            emissionRecord={emissionQuery.data}
                            onClose={props.onClose}
                        />
                    )}
                </>
            )}
        </FullscreenModal>
    );
};
